import React from "react";

import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import NotificationTypeIcon from "../NotificationTypeIcon/NotificationTypeIcon";
import { formatDistanceToNowStrict } from "date-fns";
import { NOTIFICATION_ROUTING_LOCATIONS } from "../../utils/notificationRoutingLocations";
import { markNotificationAsSeen } from '../../store/actions/notificationsAction'

export default function NotificationListItem({
  id,
  title,
  timestamp,
  path,
  handleClose,
  handleNotificationSeen,
  isSeen
}) {
  let route;
  const dispatch = useDispatch();

  const markAsSeen = () => {
    handleNotificationSeen(id);
  };

  switch (path) {
    case NOTIFICATION_ROUTING_LOCATIONS.announcementPage:
      route = `/announcement/${id}`;
      break;
    case NOTIFICATION_ROUTING_LOCATIONS.teamCalendar:
      route = "/time-off";
      break;
    case NOTIFICATION_ROUTING_LOCATIONS.organizationDetails:
      route = "/organizations";
      break;
    default:
      route = "/";
  }

  return (
    <ListItem
      button
      alignItems="flex-start"
      key={id}
      component={Link}
      to={`${route}`}
      onClick={markAsSeen}
    >
      <ListItemAvatar>
        <Avatar>
          <NotificationTypeIcon type={path} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={isSeen ?
          (<Typography>
            {title}
          </Typography>) :
          (<Typography style = {{ fontWeight: "450" }}>
          {title}
            </Typography>)}
        secondary={formatDistanceToNowStrict(new Date(timestamp)) + " ago"}
        secondaryTypographyProps={{ variant: "caption" }}
      />
    </ListItem>
  );
}
