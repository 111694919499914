import PropTypes from 'prop-types'
import React from 'react'

import { withStyles } from "@material-ui/core/styles"
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

let navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  navButtons: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
})

class CalendarToolbar extends React.Component {
  render() {
    const { classes } = this.props

    let {
      localizer: { messages },
      label,
    } = this.props

    return (
      <div className={classes.root}>
        <nav>
          <Button
            type="button"
            variant="outlined"
            onClick={this.navigate.bind( null, navigate.TODAY )}
          >
            {messages.today}
          </Button>
          <span className={classes.navButtons}>
            <Tooltip title={messages.previous} aria-label={messages.previous}>
              <IconButton
                size="small"
                type="button"
                onClick={this.navigate.bind( null, navigate.PREVIOUS )}
              >
                <NavigateBeforeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={messages.next} aria-label={messages.next}>
              <IconButton
                size="small"
                type="button"
                onClick={this.navigate.bind( null, navigate.NEXT )}
              >
                <NavigateNextIcon />
              </IconButton>
            </Tooltip>
          </span>
        </nav>
        <Typography variant="subtitle1">{label}</Typography>
      </div>
    )
  }

  navigate = action => {
    this.props.onNavigate( action )
  }
}

CalendarToolbar.propTypes = {
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
}

export default withStyles(useStyles, { withTheme: true })(CalendarToolbar)
