import React from "react";
import JoditEditor from "jodit-react";
import { makeStyles, FormHelperText } from "@material-ui/core";

const DEFAULT_TOOLBAR_BUTTONS = [
  {
    group: "font-style",
    buttons: [],
  },
  {
    group: "script",
    buttons: [],
  },
  {
    group: "list",
    buttons: ["ul", "ol"],
  },
  {
    group: "indent",
    buttons: [],
  },
  {
    group: "font",
    buttons: [],
  },
  {
    group: "color",
    buttons: [],
  },
  {
    group: "media",
    buttons: [],
  },
  {
    group: "state",
    buttons: [],
  },
  {
    group: "clipboard",
    buttons: [],
  },
  {
    group: "insert",
    buttons: [],
  },
  {
    group: "form",
    buttons: [],
  },
  {
    group: "history",
    buttons: [],
  },
  {
    group: "search",
    buttons: [],
  },
  {
    group: "source",
    buttons: [],
  },
  {
    group: "other",
    buttons: [],
  },
  {
    group: "info",
    buttons: [],
  },
];

const useStyles = makeStyles((theme) => ({
  '@global': {
    ".jodit-container:not(.jodit_inline)": {
      border: "0px solid black"
    }
  },

  readOnly: {
    padding: "0px !important",

    "& ~ .jodit-placeholder": {
      fontSize: "1rem !important",
      padding: "14px",
      color: "#0000008a",
    },
  },

  standard: {
    padding: "14px !important",
    border: "1px solid #dadada",

    "& ~ .jodit-placeholder": {
      fontSize: "1rem !important",
      padding: "14px",
      color: "#0000008a",
    },
  },

  hasErrors: {
    border: "1px solid #f44336",
    padding: "14px !important",

    "& ~ .jodit-placeholder": {
      padding: "14px",
      color: "#f44336",
      fontSize: "1rem !important", // This repetition is necessary as the editor currently suppports a single class
    },
  },
}));

// How to use:
// const editorRef = useRef(null)
// const [value, setValue] = useState('');
//
// <RichTextEditor
//  editorRef={editorRef}
//  value={value}
//  isReadOnly={false}
//  error={error} // The error text
//  touched={touched} // Boolean, if the editor was ever touched
//  placeholder="Content *"
//  onBlur={(newContent) => setValue(newContent)} // Use onBlur instead on onChange for performance reasons
//  disabled={false}
//  buttons={buttons} // An array of objects describing the buttons in the toolbar. See https://xdsoft.net/jodit/doc/options/buttons/ for the structure and all of the options
///>

export const RichTextEditor = ({
  editorRef,
  value,
  onBlur,
  placeholder = "Write something awesome!",
  error,
  touched,
  isReadOnly = false,
  disabled = false,
  buttons = null,
  showWordsCounter = false,
  showCharsCounter = false,
  showToolbar = false,
  showStatusBar = false,
  tabIndex = 0,
}) => {
  const classes = useStyles();
  const hasErrors = touched && error;

  const config = {
    readonly: isReadOnly, // all options from https://xdsoft.net/jodit/doc/,
    placeholder: placeholder,
    editorCssClass: isReadOnly ? classes.readOnly : hasErrors? classes.hasErrors : classes.standard,
    buttons: buttons !== null ? buttons : DEFAULT_TOOLBAR_BUTTONS,
    disabled: disabled,
    tabIndex: tabIndex,
    toolbarAdaptive: false,
    showWordsCounter: showWordsCounter,
    showCharsCounter: showCharsCounter,
    toolbar: showToolbar,
    statusbar: showStatusBar,
  };

  return (
    <>
      <JoditEditor
        config={config}
        ref={editorRef}
        value={value}
        onBlur={onBlur}
      />
      {hasErrors && (
        <FormHelperText error variant="outlined">
          {error}
        </FormHelperText>
      )}
    </>
  );
};
