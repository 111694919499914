import { announcementsReducerActionTypes } from "../actionTypes";

const {
  GET_ANNOUNCEMENT_START,
  GET_ANNOUNCEMENT_SUCCESS,
  GET_ANNOUNCEMENT_FAILED,
  PUBLISH_ANNOUNCEMENT_START,
  PUBLISH_ANNOUNCEMENT_SUCCESS,
  PUBLISH_ANNOUNCEMENT_FAILED,
} = announcementsReducerActionTypes;

const initialState = {
  selectedAnnouncement: null,
  error: null,
  loading: false,
};

const reducerMap = {
  [GET_ANNOUNCEMENT_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [GET_ANNOUNCEMENT_SUCCESS]: (state, action) => {
    return {
      ...state,
      selectedAnnouncement: action.payload,
      error: null,
      loading: false,
    };
  },
  [GET_ANNOUNCEMENT_FAILED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      selectedAnnouncement: null,
      loading: false,
    };
  },
  [PUBLISH_ANNOUNCEMENT_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [PUBLISH_ANNOUNCEMENT_SUCCESS]: (state, action) => {
    return { ...state, error: null, loading: false };
  },
  [PUBLISH_ANNOUNCEMENT_FAILED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      loading: false,
    };
  },
};

const announementReducer = (state = initialState, action) => {
  const fn = action.type && reducerMap[action.type];
  return fn ? fn(state, action) : state;
};

export default announementReducer;
