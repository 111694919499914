import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Formik
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

// MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Paper, Grid, Box, Button } from '@material-ui/core/';

// MUI Icons
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';

// Actions
import {
  getCETeamUserProfileById,
  updateCETeamMember,
} from '../../../../store/actions/ceTeamAction';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '100%',
    height: '300px',
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

const EditProfilePage = (props) => {
  const userId = Number(props.match.params.id);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.ceTeamMembers.userProfile);

  useEffect(() => {
    dispatch(getCETeamUserProfileById(userId));
  }, [dispatch, userId]);

  return (
    userId === userProfile.userId && (
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={{
                userId: userProfile?.userId,
                displayName: userProfile?.displayName,
                skills: userProfile?.skills,
                interests: userProfile?.interests,
                imageURL: userProfile?.imageURL,
              }}
              onSubmit={(values, { setSubmitting }) => {
                try {
                  dispatch(updateCETeamMember(values));
                } catch (ok) {}
                setSubmitting(false);
              }}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, touched, errors }) => (
                <Form>
                  <Avatar
                    variant="square"
                    className={classes.avatar}
                    alt={userProfile?.displayName}
                    src={userProfile?.imageURL}
                  />
                  <Field
                    component={TextField}
                    name="displayName"
                    type="text"
                    label="Profile Name"
                    required
                    fullWidth
                    margin="normal"
                    disabled
                  />
                  <Field
                    component={TextField}
                    name="skills"
                    type="text"
                    label="Skills"
                    required
                    fullWidth
                    margin="normal"
                    value={userProfile?.skills}
                  ></Field>
                  <Field
                    component={TextField}
                    name="interests"
                    type="text"
                    label="Interests"
                    required
                    fullWidth
                    margin="normal"
                    value={userProfile?.interests}
                  ></Field>

                  <Box pt={4}>
                    <Button
                      startIcon={<CreateIcon />}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Update
                    </Button>
                    <Button
                      startIcon={<CancelIcon />}
                      className={classes.button}
                      variant="contained"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    )
  );
};

export default EditProfilePage;
