import { callApiEndpoint, REQUEST_TYPES } from '../../utils/storeFunctions';
import { ceTeamReducerActionTypes } from '../actionTypes';
import { userEndpoints } from '../endpoints';

const {
  FETCH_CE_TEAM_MEMBERS_START,
  FETCH_CE_TEAM_USER_PROFILE_START,
  UPDATE_CE_TEAM_MEMBER_START,
} = ceTeamReducerActionTypes;

export const getAllCETeamMembers = () => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.GET_CE_TEAMMEMBERS,
      actionTypes: FETCH_CE_TEAM_MEMBERS_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};

export const getCETeamUserProfileById = (userId) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.GET_PROFILE(userId),
      actionTypes: FETCH_CE_TEAM_USER_PROFILE_START,
      requestType: REQUEST_TYPES.GET,
      returnEmptyOn404: true
    });
  };
};

export const updateCETeamMember = (user) => {
  return (dispatch) => {
    const data = new FormData();
    data.append('UserId', user.userId);
    data.append('Skills', user.skills);
    data.append('Interests', user.interests);
    data.append('ImageFilename', user.imageFilename);
    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.UPDATE_PROFILE,
      actionTypes: UPDATE_CE_TEAM_MEMBER_START,
      requestType: REQUEST_TYPES.POST,
      data: data,
      payload: user,
      returnPath: '/team-profiles',
      message: 'User successfully updated!',
      postType: 'update',
    });
  };
};
