import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncement } from "../store/actions/announcementActions";
import { Grid, Paper, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RichTextEditor } from "../components/RichTextEditor/RichTextEditor";
import NotFoundMessage from "../components/NotFoundMessage/NotFoundMessage";
import SearchIcon from "@material-ui/icons/Search";
import { NOTIFICATION_ROUTING_LOCATIONS } from "../utils/notificationRoutingLocations";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: "12px",
    fontSize: "1.2rem",
  },
  publishedAt: {
    marginTop: "10px",
    fontSize: "0.9rem",
  },
}));

const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
};

const ViewAnnouncement = () => {
  const classes = useStyles();

  const { id } = useParams();

  const announcement = useSelector(
    (state) => state.announcements.selectedAnnouncement
  );

  const loading = useSelector((state) => state.announcements.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnnouncement(id));
  }, [id, dispatch]);

  const isValidAnnouncement =
    !!announcement &&
    announcement.routingDetails.location ===
      NOTIFICATION_ROUTING_LOCATIONS.announcementPage;

  if (loading) {
    return <CircularProgress />;
  }

  if (isValidAnnouncement === false) {
    return (
      <NotFoundMessage
        message="Sorry, we couldn't find the announcement"
        icon={<SearchIcon />}
      />
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography component="h1" className={classes.title}>
            {announcement.title}
          </Typography>
          <RichTextEditor
            isReadOnly={true}
            value={announcement.body}
            buttons={[]}
          />
          <Typography className={classes.publishedAt}>
            {`Published ${new Date(announcement.createdAt).toLocaleString(
              "en-US",
              DATE_OPTIONS
            )}`}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ViewAnnouncement;
