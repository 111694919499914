import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  Avatar,
  Chip,
  Paper,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  Box,
} from '@material-ui/core/';
import {
  AvatarGroup,
} from '@material-ui/lab/';

import Title from './../../../components/Title/Title';
import EmptyState from './../../../components/EmptyState/EmptyState';

import { formatDistanceToNowStrict, formatISO, formatRFC7231 } from 'date-fns';

// HACK: Hard-coded dummy data
import { default as dummyData } from './../../../dummyData/ticketsData.js';

// TODO: Wire this to real data
const isEmpty = false;

export default function TeamSummary() {
  if (isEmpty) {
    return (<EmptyState></EmptyState>);
  }

  return (
    <Paper>
      <Box p={2} pb={0}>
        <Title>Recent Tickets</Title>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Participants</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {dummyData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography>
                  {row.title}
                </Typography>
              </TableCell>
              <TableCell>
                <AvatarGroup max={5}>
                  {[...new Array(row.participants)].map((_, index) => (
                    <Tooltip title="Name Surname" arrow>
                      <Avatar alt={row.title} src={`https://placekitten.com/300/30${index}`} />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </TableCell>
              <TableCell>
                <Chip
                  label={row.status}
                  variant="outlined"
                />
              </TableCell>
              <TableCell>
                <Tooltip title={formatRFC7231(row.timestamp)} arrow interactive>
                  <time datetime={formatISO(row.timestamp)}>
                    {formatDistanceToNowStrict(row.timestamp)} ago
                  </time>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan="5">
              <Link component={RouterLink} to="/service-desk">
                See all tickets on the Service Desk
              </Link>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}
