import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as yup from "yup";

import store from "./store/store";
import authModule from "./services/AuthModule";
import { MsalProvider } from "@azure/msal-react";

let persistor = persistStore(store);

const msalInstance = authModule.myMSALObj;

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Extensions to Yup validation. (Not confident of whether this is its proper place):
yup.addMethod(yup.number, "multipleOf", function (factor, errorMessage) {
  return this.test('multiple-of', errorMessage, function (value) {
    return value % factor === 0;
  });
});
