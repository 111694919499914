import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Box, LinearProgress, Button } from '@material-ui/core/';
import MuiTextField from '@material-ui/core/TextField';

import Title from './../../../components/Title/Title';

import { createUser } from '../../../store/actions/userAction';
import { UserValidationSchema } from '../../../validations/schemas/UserValidationSchema';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '100%',
    height: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

const AddUser = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.roles.roles);

  return (
    <Grid container spacing={3} justify="center">
      {/* <Grid item xs={12} md={3}>
        <Avatar
          className={classes.avatar}
          alt={`${object.firstname} ${object.lastname}`}
          src={object.image}
        />
      </Grid> */}
      <Grid item xs={12} md={8}>
        <Paper className={classes.paper}>
          <Box pt={1} pb={2}>
            <Title>Add User</Title>
          </Box>

          <Formik
            initialValues={{
              email: '',
              displayName: '',
              roles: [],
            }}
            onSubmit={(values, { setSubmitting }) => {
              try {
                dispatch(createUser(values));
                
              } catch (ok) {}

              setSubmitting(false);
            }}
            validationSchema={UserValidationSchema}
          >
            {({ submitForm, isSubmitting, touched, errors }) => (
              <Form>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                />
                <br />
                <Field
                  component={TextField}
                  type="text"
                  label="Profile Name"
                  name="displayName"
                  margin="normal"
                  fullWidth
                />
                {isSubmitting && <LinearProgress />}
                <br />
                <Field
                  name="roles"
                  component={Autocomplete}
                  multiple
                  options={roles}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  fullWidth
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Roles"
                      margin="normal"
                      error={touched['roles'] && !!errors['roles']}
                      helperText={touched['roles'] && errors['roles']}
                      variant="outlined"
                    />
                  )}
                />
                <br />
                <br />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Submit
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={() => {
                    window.location.href = '/users';
                  }}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>

          {/* <form>
            <TextField
              id="email"
              label="Email"
              required
              fullWidth
              margin="normal"
            />
            <TextField
              id="firstname"
              label="First Name"
              required
              fullWidth
              margin="normal"
            />
            <TextField
              id="lastname"
              label="Last Name"
              required
              fullWidth
              margin="normal"
            />
            <MultipleSelect
              optionList={[
                { id: 'admin', title: 'Admin' },
                { id: 'team-member', title: 'Team Member' },
                { id: 'client', title: 'Client' },
              ]}
              label="Roles *"
            />
            <MultipleSelect
              optionList={organizationsData.map((row) => (
                { id: row.id, title: row.name }
              ))}
              label="Organizations"
            />

            <Box pt={4}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => { window.location.href = '/users'; }}
              >
                Cancel
              </Button>
            </Box>
          </form> */}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddUser;
