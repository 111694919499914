import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const graphColors = {
  mainDark: '#d56223',
  mainLight: '#ffd558',
  additionalDark: '#072d44',
  additionalLight: '#6ca8cc'
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "10px",
  },
  graph: {
    position: "relative",
    maxHeight: "90px",
    minHeight: "60px",
    paddingTop: "15%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: "18%",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "0",
      height: "90px",
    },
  },
  label: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
  },
  caret: {
    position: "absolute",
    bottom: `calc(100% + ${theme.spacing(1)}px)`,
    right: "-2px",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      right: 0,
      top: `calc(100% - ${theme.spacing(0.5)}px)`,
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px 0`,
      borderColor: "transparent",
      borderRightColor: theme.palette.grey[700],
    },
  },
  box: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    border: "2px solid",
    borderColor: graphColors.mainDark,
  },
  legend: {
    position: "relative",
    top: "2px",
    display: "inline-block",
    width: "20px",
    height: "20px",
    border: "1px solid",
    borderColor: graphColors.mainDark,
    marginRight: theme.spacing(1),
  },
  overage: {
    backgroundColor: graphColors.additionalLight,
    border: "none",
  },
  additional: {
    borderColor: graphColors.additionalDark,
  },
  unapproved: {
    borderColor: graphColors.additionalDark,
    borderStyle: "dashed",
  },
  completed: {
    backgroundColor: graphColors.mainLight,
    border: "none",
  },
  target: {},
  contracted: {
    background: "transparent",
    borderStyle: "dashed",
    borderColor: graphColors.mainDark,
  },
  cardActionsContainer: {
    justifyContent: "space-between",
    padding: "16px 16px 4px 16px",
  },
}));

const HoursGraph = (props) => {
  const classes = useStyles();

  //Validations
  const data = props.organizationHourSummary
    ? props.organizationHourSummary
    : {};
  const contractedHours = data?.byContract || 0;
  const targetHours = data?.appSupportTarget || 0;
  const currentHours = data?.appSupportWork || 0;
  const additionalHours = data?.additionalWork || 0;
  const additionalHoursApproved = data?.additionalWorkTarget || 0;
  const borrowedHours = data?.nextMonthBorrowApproved || 0;

  const totalApprovedAppSupportHrs = targetHours + borrowedHours;

  // if we don't wat to show unapproved hours set this to false
  const showUnapproved = true;
  const completedHrs = showUnapproved
    ? currentHours
    : Math.min(currentHours, totalApprovedAppSupportHrs);

  const maxWorkHrs = Math.max(
    completedHrs,
    totalApprovedAppSupportHrs,
    contractedHours
  );
  const maxAdditionalHrs = Math.max(additionalHoursApproved, additionalHours);
  const maxTotalHrs = maxWorkHrs + maxAdditionalHrs;

  const hasCompleted = completedHrs > 0;

  // calculate bar sizes
  function calculateSize(hours) {
    return (hours / maxTotalHrs) * 100;
  }
  const sizeTotalAppHrs = calculateSize(maxWorkHrs);
  const sizeTarget = calculateSize(totalApprovedAppSupportHrs);
  const sizeContracted = calculateSize(contractedHours);
  const sizeCompletedHrs = calculateSize(completedHrs);
  const sizeAdditionalHrs = calculateSize(additionalHours);
  const sizeAdditionalApproved = calculateSize(additionalHoursApproved);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {props.title}
        </Typography>

        <div className={classes.graph}>
          {/*Additional Hours section*/}
          {additionalHours > 0 && (
            <div
              className={clsx(classes.box, classes.overage)}
              style={{
                width: `${sizeAdditionalHrs}%`,
                left: `${sizeTotalAppHrs}%`,
              }}
            ></div>
          )}
          {additionalHoursApproved > 0 && (
            <div
              className={clsx(classes.box, classes.additional)}
              style={{
                width: `${sizeAdditionalApproved}%`,
                left: `${sizeTotalAppHrs}%`,
              }}
            ></div>
          )}

          {/*App Support completed hours*/}
          {hasCompleted && (
            <div
              className={clsx(classes.box, classes.completed)}
              style={{ width: `${sizeCompletedHrs}%` }}
            ></div>
          )}

          {/*App support target and by contract*/}
          <div
            className={clsx(classes.box, classes.contracted)}
            style={{ width: `${sizeContracted}%` }}
          >
            <Typography className={classes.caret} variant="caption">
              {contractedHours === 0 ? "" : `${contractedHours} contracted`}
            </Typography>
          </div>
          <div
            className={clsx(classes.box, classes.target)}
            style={{ width: `${sizeTarget}%` }}
          ></div>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} key="completed">
            <Typography variant="h4" component="h2">
              <span className={clsx(classes.legend, classes.completed)}></span>
              {`${completedHrs} completed`}
            </Typography>

            {contractedHours > 0 && (
              <Typography variant="body1" component="h3">
                <span
                  className={clsx(classes.legend, classes.contracted)}
                ></span>
                {`${contractedHours} contracted`}
              </Typography>
            )}

            <Typography variant="body1" component="h3">
              <span className={clsx(classes.legend, classes.target)}></span>
              {borrowedHours > 0
                ? `${targetHours} target + ${borrowedHours} borrowed`
                : `${targetHours} target`}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} key="additional">
            {(additionalHoursApproved > 0 || additionalHours > 0) && (
              <>
                <Typography variant="h4" component="h2">
                  <span
                    className={clsx(classes.legend, classes.overage)}
                  ></span>
                  {`${additionalHours} additional`}
                </Typography>
                <Typography variant="body1" component="h3">
                  <span
                    className={clsx(classes.legend, classes.additional)}
                  ></span>
                  {`${additionalHoursApproved} additional approved`}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>

      {props.actions && (
        <CardActions className={classes.cardActionsContainer}>
          {props.actions}
        </CardActions>
      )}
    </Card>
  );
};

export default HoursGraph;
