import { createMuiTheme } from '@material-ui/core/styles';
import rubik from "typeface-rubik";

const theme = {
  palette: {
    primary: {
      // main: '#03a8f5', // MM Blue
      // main: '#223f6f', // MM Navy Light
      main: 'rgba(242, 150, 23, .8)', // MM Orange
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#ff5252',
      contrastText: '#ffffff'
    },
  },
  typography: {
    fontFamily: 'Rubik, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [rubik],
      },
    },
  },
};

export default createMuiTheme(theme);
