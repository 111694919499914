import React from 'react';
import logo from './mentormate-logo.svg';
import { NavLink } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    width: '100%',
    verticalAlign: 'bottom'
  },
  link: {
    flex: 'auto',
  }
}));

export default function Logo() {
  const classes = useStyles();

  return (
    <NavLink to="/" className={classes.link}>
      <img src={logo} alt="logo" className={classes.img} />
    </NavLink>
  );
}
