import React from 'react';
import { Link } from 'react-router-dom';
import { ROLE_NAMES } from "../../../../utils/roles";
import authModule from "../../../../services/AuthModule";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom'

import {
  BottomNavigation,
  BottomNavigationAction
}
  from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from "@material-ui/icons/Business";

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing( 1 ),
    zIndex: 1,
    boxShadow: theme.shadows[ 10 ],
  },
  icon: {
    color: theme.palette.primary.main
  },
} ) );

export default function BottomNavigationBar() {
  const classes = useStyles();
  //const [value, setValue] = React.useState(window.location.pathname);
  const location = useLocation();

  const orgBtn = authModule.getRoles().some((role) => role === ROLE_NAMES.Admin || role === ROLE_NAMES.CETeamMember)
    ?
    <BottomNavigationAction
      component={Link}
      label="Organizations"
      icon={<BusinessIcon />}
      value="/organizations"
      to="/organizations"
      className={classes.icon} />
    :
    <BottomNavigationAction
      component={Link}
      label="Hours Details"
      icon={<AccessTimeIcon />}
      value="/organizations"
      to="/organizations"
      className={classes.icon} />;

  const teamBtn =
    <BottomNavigationAction
      component={Link}
      label="Team"
      icon={<PeopleIcon />}
      value="/team-profiles"
      to="/team-profiles"
      className={classes.icon} />;

  const timeOffBtn =
    <BottomNavigationAction
      component={Link}
      label="Time Off"
      icon={<CalendarTodayIcon />}
      value="/time-off"
      to="/time-off"
      className={classes.icon} />;

  return (
    <BottomNavigation
      value={location.pathname}
      showLabels
      className={classes.root}
    >
      {orgBtn}
      {teamBtn}
      {timeOffBtn}
    </BottomNavigation>
  );
}
