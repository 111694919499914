import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getAllowedRoutes } from "../../../../routing/routes";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

export default function AppBarTitle() {
  const classes = useStyles();
  const routes = useMemo(getAllowedRoutes, []);

  return (
    <Typography
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      className={classes.title}
    >
      {
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} exact={route.exact} path={route.path}>
              {route.title}
            </Route>
          ))}
        </Switch>
      }
    </Typography>
  );
}
