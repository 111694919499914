import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import errorHandlingMiddleware from "./errorHandlingMiddleware";

import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  blacklist: ["do-not-store"],
  storage,
};

const middlewares = [thunk, errorHandlingMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: middlewares,
});

export default store;
