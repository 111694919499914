import { notificationsReducerActionTypes } from '../actionTypes';

const { FETCH_ALL_NOTIFICATIONS_START, FETCH_ALL_NOTIFICATIONS_SUCCESS, FETCH_ALL_NOTIFICATIONS_FAILED } = notificationsReducerActionTypes;

const initialState = {
  notifications: [],
}

const reducerMap = {
  [FETCH_ALL_NOTIFICATIONS_START]: (state = initialState, action) => {
    return {
      ...state,
      loading: true
    };
  },
  [FETCH_ALL_NOTIFICATIONS_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      notifications: action.payload,
      loading: false
    };
  },
  [FETCH_ALL_NOTIFICATIONS_FAILED]: (state = initialState, action) => {
    return {
      ...state,
      error: true,
      loading: false
    };
  },
}

const notificationsReducer = (state = initialState, action) => {
  let fn = null;
  fn = action.type && reducerMap[action.type];
  return fn ? fn(state, action) : state;
};

export default notificationsReducer;
