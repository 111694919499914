import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  TablePagination,
  Box,
  Button,
  Grid,
  TextField,
} from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import CircularProgress from "@material-ui/core/CircularProgress";

import { setCurrentUser } from "../../../store/actions/userAction";
import { getAllOrganizationsByUserId } from "../../../store/actions/organizationAction";
import NotFoundMessage from "../../../components/NotFoundMessage/NotFoundMessage";


const useStyles = makeStyles((theme) => ({
  addButton: {
    marginBottom: "20px",
  },
  icon: {
    color: theme.palette.primary.main
  }
}));

const TableContent = (props) => {
  const dispatch = useDispatch();

  const isEmpty = props.users.length === 0;

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const loading = useSelector((state) => state.users.loading);
  
  

  const handleClick = (user) =>  {
    dispatch(setCurrentUser(user));
    dispatch(getAllOrganizationsByUserId(user.id));
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.setPage(0);
  };

  if (isEmpty) {
    return (
      <Box p={2} pb={0}>
        <NotFoundMessage icon={<SearchIcon/>} message="No matches found" />
      </Box>
    );
  }
  return (
    <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.users
              ?.slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.displayName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.roles.map((role) => role.name).join(", ")}
                  </TableCell>
                  <TableCell>{user.isSuspended ? "suspended" : "active"}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      component={RouterLink}
                      to={`/users/${user.id}/edit`}
                      startIcon={<EditIcon />}
                      onClick={() => handleClick(user)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                // colSpan={5}
                count={props.users?.length}
                page={props.page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      
    </Box>
  );
};

const UsersList = (props) => {
  const classes = useStyles();
 

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Paper>
          <Box p={2} pb={0}>
            <Grid container justify="flex-end" >
              <Button
                size="medium"
                variant="outlined"
                component={RouterLink}
                to="/users/add"
                startIcon={<AddIcon className={classes.icon} />}
                className={classes.addButton}
              >
                Add User
              </Button>
            </Grid>
            <Box>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                value={props.input}
                onChange={props.onChange}
                onKeyUp={(event) => {
                  if (event.key === "Escape") {
                    props.setInput("");
                  }
                }}
                fullWidth
              />
            </Box>
          </Box>
          <TableContent users={props.users} page={props.page} setPage={props.setPage}/>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UsersList;
