import { useIsAuthenticated } from "@azure/msal-react";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import authModule from "../services/AuthModule";

const PrivateRoute = ({ path, exact, component, roles }) => {
  const isAuthenticated = useIsAuthenticated();

  const isInAllowedRole = roles
    ? authModule.isInAllowedRole(roles)
    : roles?.length === 0
    ? false // An empty array of roles means no roles are allowed to access
    : true; // roles is falsy and is not an array => every role can access

  if (isAuthenticated === false || isInAllowedRole === false) {
    return <Redirect to="/organizations" />;
  }

  return path === "/" ? (
    <Redirect to="/organizations" />
  ) : (
    <Route path={path} exact={exact} component={component} />
  );
};

export default PrivateRoute;
