import { eventsReducerActionTypes } from "../actionTypes";

const {
    FETCH_MONTHLY_EVENTS_START,
    FETCH_MONTHLY_EVENTS_SUCCESS,
    FETCH_MONTHLY_EVENTS_FAILED,
} = eventsReducerActionTypes;

const initialState = {
    events: [],
    loading: null,
    error: null,
}

const reducerMap = {
    [FETCH_MONTHLY_EVENTS_START]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [FETCH_MONTHLY_EVENTS_SUCCESS]: (state, action) => {
      return {
        ...state,
        events: action.payload,
        loading: false,
      };
    },
    [FETCH_MONTHLY_EVENTS_FAILED]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
};

const eventsReducer = (state = initialState, action) => {
    let fn = null;
    fn = action.type && reducerMap[action.type];
    return fn ? fn(state, action) : state;
};

export default eventsReducer;