import {
  callApiEndpoint,
  callApiEndpointNoMessages,
  REQUEST_TYPES,
} from "../../utils/storeFunctions";
import { announcementsReducerActionTypes } from "../actionTypes";
import { announcementEndpoints } from "../endpoints";

export const getAnnouncement = (id) => {
  return async (dispatch, getState) => {
    try {
      // Avoid making an API call if the requested announcement is already in the store
      if (getState().announcements.selectedAnnouncement?.id === parseInt(id)) {
        return;
      }

      dispatch({
        type: announcementsReducerActionTypes.GET_ANNOUNCEMENT_START,
      });
      const response = await callApiEndpointNoMessages({
        endpoint: announcementEndpoints.GET(id),
        requestType: REQUEST_TYPES.GET,
        timeout: 30000,
      });
      dispatch({
        type: announcementsReducerActionTypes.GET_ANNOUNCEMENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: announcementsReducerActionTypes.GET_ANNOUNCEMENT_FAILED,
        payload: error.response,
        suppressGlobalError: true,
      });
    }
  };
};

export const publishAnnouncement = ({
  title,
  body,
  organizationsIds,
  rolesIds,
  isEmailed = false,
  routingLocation = "AnnouncementPage",
}) => {
  return (dispatch) => {
    callApiEndpoint({
      requestType: REQUEST_TYPES.POST,
      dispatch,
      endpoint: announcementEndpoints.PUBLISH,
      actionTypes: announcementsReducerActionTypes.PUBLISH_ANNOUNCEMENT_START,
      data: {
        title: title,
        body: body,
        organizationsIds: organizationsIds,
        rolesIds: rolesIds,
        isEmailed: isEmailed,
        routingLocation: routingLocation,
      },
      contentType: "application/json",
      timeout: 30000,
      message: "Announcement published",
    });
  };
};
