import * as yup from "yup";

export const UserValidationSchema = yup.object({
    email: yup
        .string()
        .email("Use valid email!")
        .required("Email is required!"),
    displayName: yup
        .string()
        .min(3, "Profile Name should be at least 3 characters")
        .required("Profile Name is required!"),
    roles: yup
        .array()
        .min(1, "At least one role is required!")
        .required("Roles are required!")
        .of(
            yup
                .object()
                .shape({
                    id: yup
                        .number()
                        .required(),
                    name: yup
                        .string()
                        .required()
                })
        )
});
