import { roleReducerActionTypes } from "../actionTypes";

const { FETCH_ROLES_START, FETCH_ROLES_SUCCESS, FETCH_ROLES_FAILED } =
  roleReducerActionTypes;

const initialState = {
  roles: [],
  loading: null,
  error: null,
};

const reducerMap = {
  [FETCH_ROLES_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_ROLES_SUCCESS]: (state, action) => {
    return {
      ...state,
      roles: action.payload,
      loading: false,
    };
  },
  [FETCH_ROLES_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
};

const roleReducer = (state = initialState, action) => {
  let fn = null;
  fn = action.type && reducerMap[action.type];
  return fn ? fn(state, action) : state;
};

export default roleReducer;
