import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: "100vh",
  },
  iconContainer: {
    "& > svg": {
      fill: "#bdbdbd",
      fontSize: 170,
      "@media (max-width:600px)": {
        fontSize: 100,
      },
    },
  },
  message: {
    color: "#bdbdbd",
    fontSize: 25,
    "@media (max-width:600px)": {
      fontSize: 15,
    },
    align: "center",
  },
}));

const NotFoundMessage = ({ message, icon }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.gridContainer}
    >
      {icon && (
        <Grid item xs={3} className={classes.iconContainer}>
          {icon}
        </Grid>
      )}
      <Grid item xs={icon ? 6 : 12}>
        <Typography className={classes.message}>{message}</Typography>
      </Grid>
    </Grid>
  );
};

export default NotFoundMessage;
