import { callApiEndpoint, REQUEST_TYPES } from '../../utils/storeFunctions';
import { eventsReducerActionTypes } from '../actionTypes';
import { eventEndpoints } from '../endpoints';

const {
    FETCH_MONTHLY_EVENTS_START,
} = eventsReducerActionTypes;

export const getMonthlyEvents = (month, year) => {
    return (dispatch) => {
      callApiEndpoint({
        dispatch,
        endpoint: eventEndpoints.GET_FOR_MONTH(month, year),
        actionTypes: FETCH_MONTHLY_EVENTS_START,
        requestType: REQUEST_TYPES.GET,
        timeout: 25000,
      });
    };
  };