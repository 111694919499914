import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import OrganizationsList from './components/OrganizationsList/OrganizationsList';
import { getAllOrganizations} from '../store/actions/organizationAction';


const Organizations = () => {
  const [page, setPage] = useState(0);
  const [input, setInput] = useState('');
  const dispatch = useDispatch();
  
  const organizations = useSelector((state) => state.organizations.organizations);
  const history = useHistory();
  
  useEffect(() => {
    if (organizations.length === 0) {
      dispatch(getAllOrganizations());
    }

    const appSupportOrgs = organizations.filter((organization) => organization.contractTypeId === 2);
    
    if (appSupportOrgs.length === 1) {
      history.push(`/organization-details/${appSupportOrgs[0].id}`);
    }
    //eslint-disable-next-line
  }, [dispatch, organizations.length]);

  const editInput = (e) => {
    setPage(0);
    setInput(e.target.value);
  };

  const dynamicSearch = () => {
    return organizations.filter((organization) =>
    (organization.name.toLowerCase().includes(input.toLowerCase())
      && organization.contractTypeId === 2)
    ).sort((firstOrganization, secondOrganization) => {
      if (firstOrganization.isActive && !secondOrganization.isActive) return -1;
      else if (!firstOrganization.isActive && secondOrganization.isActive) return 1;
      else {
        return (firstOrganization.displayName > secondOrganization.displayName) ? 1
          : ((secondOrganization.displayName > firstOrganization.displayName) ? -1 : 0);
      }
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <OrganizationsList
          organizations={dynamicSearch()}
          input={input}
          setInput={setInput}
          onChange={editInput}
          page = {page}
          setPage = {setPage}
        />
      </Grid>
    </Grid>
  );
};

export default Organizations;
