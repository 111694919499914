import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllNotifications } from '../store/actions/notificationsAction';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import NotificationPanel from './components/NotificationPanel/NotificationPanel';

const useStyles = makeStyles((theme) => ({
  list: {
    marginBottom: theme.spacing(1),
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const notifications = useSelector(state => state.notifications.notifications)

  useEffect(() => {
    if (notifications.length === 0) {
      dispatch(getAllNotifications(200));
    }
  }, [dispatch, notifications.length]);

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>New</Typography>
      <div className={classes.list}>
        {notifications.map((notification) => (
          <NotificationPanel
            key={notification.id}
            id={notification.id}
            title={notification.title}
            timestamp={notification.createdAt}
            seen={notification.seenAt}
            routingDetails={notification.routingDetails}
          />
        ))}
      </div>
    </div>
  );
}

