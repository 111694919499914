import { Component } from 'react';
import authModule from './services/AuthModule';

export default class SignOut extends Component {

  componentWillMount() {
    authModule.logout();
  }

  render() {
    return null;
  }
}