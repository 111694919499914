import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";

import UsersList from "./components/UsersList/UsersList";
import EmptyState from "../components/EmptyState/EmptyState";

import { getAllUsers } from "../store/actions/userAction";
import { getAllRoles } from "../store/actions/roleAction";

// TODO: Wire this to real data
const isEmpty = false;

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const roles = useSelector((state) => state.roles.roles);

  useEffect(() => {
      dispatch(getAllUsers());
      dispatch(getAllRoles());
  }, [dispatch, roles.length, users.length]);

  const[page,setPage] = useState(0);
  const [input, setInput] = useState("");

  const editInput = (e) => {
    setPage(0);
    setInput(e.target.value);
  };

  const dynamicSearch = () => {
    return users.filter((user) =>
      user.displayName?.toLowerCase().includes(input.toLowerCase()) ||
      user.email?.toLowerCase().includes(input.toLowerCase()) ||
      user.roles?.some(role => role.name.toLowerCase().includes(input.toLowerCase()))
    ).sort((firstUser, secondUser) => (firstUser.displayName > secondUser.displayName) ? 1 
      : ((secondUser.displayName > firstUser.displayName) ? -1 : 0));
  };

  if (isEmpty) {
    return <EmptyState></EmptyState>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <UsersList
          users={dynamicSearch()}
          input={input}
          setInput={setInput}
          onChange={editInput}
          page={page}
          setPage={setPage}
        />
      </Grid>
    </Grid>
  );
};

export default Users;
