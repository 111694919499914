import React from "react";
import ErrorSnackbar from "./ErrorSnackbar";
import SuccessSnackbar from "./SuccessSnackbar";

const Snackbar = () => {
  return (
    <div>
      <SuccessSnackbar />
      <ErrorSnackbar />
    </div>
  );
};

export default Snackbar;
