import { PublicClientApplication } from "@azure/msal-browser";
import { intersection } from "lodash";
import config from "../config";

const MSAL_CONFIG = {
  auth: {
    clientId: config.AUTH_CLIENTID,
    authority: config.SIGN_IN_URL,
    knownAuthorities: ["CEClientPortal.b2clogin.com"],
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

class AuthModule {
  myMSALObj;
  scopes;

  constructor() {
    if (!AuthModule.instance) {
      this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
      this.scopes = [
        "openid",
        "profile",
        "3d590f77-d2f1-4db9-9e31-f83ddc5e011d",
      ];
    }

    return AuthModule.instance;
  }

  getAccount() {
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      console.log("No accounts detected");
      return null;
    }

    if (currentAccounts.length > 1) {
      // TODO: Consider handling this case.
      // console.log(
      //   "Multiple accounts detected, need to add choose account code."
      // );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }

    return null;
  }

  getRoles() {
    var roles = this.getAccount()?.idTokenClaims["roles"]?.split(",");

    // we should not have users without a role in the system!
    if (!roles || roles.length == 0)
      this.logout();

    return roles;
  }

  isInRole(role) {
    return !!this.getRoles().some((roleName) => roleName === role);
  }

  isInAllowedRole(allowedRoles) {
    return intersection(this.getRoles(), allowedRoles).length > 0;
  }

  async login(callback) {
    let currentUrl = window.location.pathname;
    window.history.replaceState(null, "LogIn", "/");

    try {
      await this.myMSALObj.loginPopup({ scopes: this.scopes });
      callback(true);
    } catch (error) {
      callback(false);
      console.log(error);
    }

    window.history.replaceState(null, "", currentUrl);
  }

  logout() {
    const account = this.getAccount();
    const logOutRequest = { account, postLogoutRedirectUri: "/" };
    this.myMSALObj.logoutRedirect(logOutRequest);
  }

  // TODO: Check if the token is expired and request a new one using the refresh token
  async getTokenPopup() {
    const account = this.getAccount();

    const req = { account, scopes: this.scopes };

    try {
      const response = await this.myMSALObj.acquireTokenSilent(req);
      return response.accessToken;
    } catch (e) {
      console.log("Silent token acquisition fails.");
      this.logout();
    }

    return null;
  }

  isTokenExpired(expirationDate) {
    const date = new Date(0);
    date.setUTCSeconds(expirationDate);

    return date < new Date();
  }
}

const authModule = new AuthModule();

export default authModule;
