import { organizationReducerActionTypes } from "../actionTypes";

const {
  FETCH_ORGANIZATIONS_START,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILED,
  SET_SELECTED_ORGANIZATION,
  FETCH_ALL_DATE_PERIODS_START,
  FETCH_ALL_DATE_PERIODS_SUCCESS,
  FETCH_ALL_DATE_PERIODS_FAILED,
  FETCH_ORGANIZATION_HOUR_SUMMARY_START,
  FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
  FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED,
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START,
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_FAILED,
  FETCH_USER_ORGANIZATIONS_START,
  FETCH_USER_ORGANIZATIONS_SUCCESS,
  FETCH_USER_ORGANIZATIONS_FAILED,
  ASSIGN_USER_TO_ORGANIZATION_START,
  ASSIGN_USER_TO_ORGANIZATION_SUCCESS,
  ASSIGN_USER_TO_ORGANIZATION_FAILED,
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_START,
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_SUCCESS,
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_FAILED,
  REQUEST_TO_BORROW_HOURS_START,
  REQUEST_TO_BORROW_HOURS_SUCCESS,
  REQUEST_TO_BORROW_HOURS_FAILED,
  REQUEST_ADDITIONAL_HOURS_START,
  REQUEST_ADDITIONAL_HOURS_SUCCESS,
  REQUEST_ADDITIONAL_HOURS_FAILED,
  APPROVE_TO_BORROW_HOURS_START,
  APPROVE_TO_BORROW_HOURS_SUCCESS,
  APPROVE_TO_BORROW_HOURS_FAILED,
  APPROVE_ADDITIONAL_HOURS_START,
  APPROVE_ADDITIONAL_HOURS_SUCCESS,
  APPROVE_ADDITIONAL_HOURS_FAILED,
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_START,
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS,
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_FAILED,
  SEND_CURRENT_MONTH_HOURS_REPORT_START,
  SEND_CURRENT_MONTH_HOURS_REPORT_SUCCESS,
  SEND_CURRENT_MONTH_HOURS_REPORT_FAILED,
  SEND_NEW_MONTH_HOURS_REPORT_START,
  SEND_NEW_MONTH_HOURS_REPORT_SUCCESS,
  SEND_NEW_MONTH_HOURS_REPORT_FAILED,
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_START,
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_SUCCESS,
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_FAILED,
  FETCH_ORGANIZATION_USERS_START,
  FETCH_ORGANIZATION_USERS_SUCCESS,
  FETCH_ORGANIZATION_USERS_FAILED,
} = organizationReducerActionTypes;

const initialState = {
  currentUserOrganizations: {},
  organizations: [],
  allDatePeriods: [],
  selectedOrganization: {},
  organizationHourSummary: {},
  organizationOAHourSummary: {},
  organizationCurrentMonthTargetHours: {},
  organizationMonthlySummaryHours: [],
  currentOrganizationUsers: {},
  loading: null,
  loadings: {
    organizations: false,
    organizationOAHourSummary: false,
    organizationHourSummary: false,
    organizationCurrentMonthTargetHours: false,
    organizationMonthlySummaryHours: false,
    currentOrganizationUsers: false,
  },
  error: null,
};

const reducerMap = {
  [FETCH_ORGANIZATIONS_START]: (state = initialState, action) => {
    return {
      ...state,
      loading: true,
      loadings: { ...state.loadings, organizations: true },
    };
  },
  [FETCH_ORGANIZATIONS_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      organizations: action.payload,
      loading: false,
      loadings: { ...state.loadings, organizations: false },
    };
  },
  [FETCH_ORGANIZATIONS_FAILED]: (state = initialState, action) => {
    return {
      ...state,
      error: true,
      loading: false,
      loadings: { ...state.loadings, organizations: false },
    };
  },
  [SET_SELECTED_ORGANIZATION]: (state, action) => {
    return {
      ...state,
      selectedOrganization: action.payload,
      loading: false,
    };
  },
  [FETCH_ALL_DATE_PERIODS_START]: (state = initialState, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_ALL_DATE_PERIODS_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      allDatePeriods: action.payload,
      loading: false,
    };
  },
  [FETCH_ALL_DATE_PERIODS_FAILED]: (state = initialState, action) => {
    return {
      ...state,
      error: true,
      loading: false,
    };
  },
  [FETCH_ORGANIZATION_HOUR_SUMMARY_START]: (state = initialState, action) => {
    return {
      ...state,
      loadings: { ...state.loadings, organizationHourSummary: true },
    };
  },
  [FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      organizationHourSummary: action.payload,
      loadings: { ...state.loadings, organizationHourSummary: false },
    };
  },
  [FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED]: (state = initialState, action) => {
    return {
      ...state,
      error: true,
      loadings: { ...state.loadings, organizationHourSummary: false },
    };
  },
  [FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START]: (
    state = initialState,
    action
  ) => {
    return {
      ...state,
      loadings: { ...state.loadings, organizationOAHourSummary: true },
    };
  },
  [FETCH_OA_ORGANIZATION_HOUR_SUMMARY_SUCCESS]: (
    state = initialState,
    action
  ) => {
    return {
      ...state,
      organizationOAHourSummary: action.payload,
      loadings: { ...state.loadings, organizationOAHourSummary: false },
    };
  },
  [FETCH_OA_ORGANIZATION_HOUR_SUMMARY_FAILED]: (
    state = initialState,
    action
  ) => {
    return {
      ...state,
      error: true,
      loadings: { ...state.loadings, organizationOAHourSummary: false },
    };
  },
  [FETCH_USER_ORGANIZATIONS_START]: (state = initialState, action) => {
    return {
      ...state,
      loadings: { ...state.loadings, currentOrganizationUsers: true },
    };
  },
  [FETCH_USER_ORGANIZATIONS_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      currentUserOrganizations: action.payload,
      loadings: { ...state.loadings, currentOrganizationUsers: false },
    };
  },
  [FETCH_USER_ORGANIZATIONS_FAILED]: (state = initialState, action) => {
    return {
      ...state,
      error: true,
      loadings: { ...state.loadings, currentOrganizationUsers: false },
    };
  },
  [ASSIGN_USER_TO_ORGANIZATION_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [ASSIGN_USER_TO_ORGANIZATION_SUCCESS]: (state, action) => {
    state.currentUserOrganizations.organizationsIds = [...action.payload];
    return {
      ...state,
      loading: false,
      currentUserOrganizations: state.currentUserOrganizations,
    };
  },
  [ASSIGN_USER_TO_ORGANIZATION_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  },
  [APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_START]: (
    state = initialState,
    action
  ) => {
    return {
      ...state,
      loading: true,
    };
  },
  [APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_SUCCESS]: (
    state = initialState,
    action
  ) => {
    return {
      ...state,
      loading: false,
    };
  },
  [APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_FAILED]: (
    state = initialState,
    action
  ) => {
    return {
      ...state,
      error: true,
      loading: false,
    };
  },
  [REQUEST_TO_BORROW_HOURS_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [REQUEST_TO_BORROW_HOURS_SUCCESS]: (state, action) => {
    return {
      ...state,
      organizationCurrentMonthTargetHours: {
        ...state.organizationCurrentMonthTargetHours,
        requestedHoursToBorrow: action.payload.hours,
      },
      error: null,
      loading: false,
    };
  },
  [REQUEST_TO_BORROW_HOURS_FAILED]: (state, action) => {
    return { ...state, error: action.payload, loading: false };
  },
  [REQUEST_ADDITIONAL_HOURS_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [REQUEST_ADDITIONAL_HOURS_SUCCESS]: (state, action) => {
    return {
      ...state,
      organizationCurrentMonthTargetHours: {
        ...state.organizationCurrentMonthTargetHours,
        requestedAdditionalWork: action.payload.hours,
      },
      error: null,
      loading: false,
    };
  },
  [REQUEST_ADDITIONAL_HOURS_FAILED]: (state, action) => {
    return { ...state, error: action.payload, loading: false };
  },
  [APPROVE_TO_BORROW_HOURS_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [APPROVE_TO_BORROW_HOURS_SUCCESS]: (state, action) => {
    return {
      ...state,
      organizationCurrentMonthTargetHours: {
        ...state.organizationCurrentMonthTargetHours,
        approvedHoursToBorrow: action.payload.hours,
      },
      error: null,
      loading: false,
    };
  },
  [APPROVE_TO_BORROW_HOURS_FAILED]: (state, action) => {
    return { ...state, error: action.payload, loading: false };
  },

  [APPROVE_ADDITIONAL_HOURS_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [APPROVE_ADDITIONAL_HOURS_SUCCESS]: (state, action) => {
    return {
      ...state,
      organizationCurrentMonthTargetHours: {
        ...state.organizationCurrentMonthTargetHours,
        approvedAdditionalWork: action.payload.hours,
        approvedAdditionalWorkNotes: action.payload.notes,
      },
      error: null,
      loading: false,
    };
  },
  [APPROVE_ADDITIONAL_HOURS_FAILED]: (state, action) => {
    return { ...state, error: action.payload, loading: false };
  },
  [FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_START]: (state, action) => {
    return {
      ...state,
      error: null,
      loadings: {
        ...state.loadings,
        organizationCurrentMonthTargetHours: true,
      },
    };
  },
  [FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS]: (state, action) => {
    return {
      ...state,
      organizationCurrentMonthTargetHours: action.payload,
      error: null,
      loadings: {
        ...state.loadings,
        organizationCurrentMonthTargetHours: false,
      },
    };
  },
  [FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_FAILED]: (state, action) => {
    return {
      ...state,
      organizationCurrentMonthTargetHours: {},
      error: action.payload,
      loadings: {
        ...state.loadings,
        organizationCurrentMonthTargetHours: false,
      },
    };
  },
  [FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_START]: (state, action) => {
    return {
      ...state,
      error: null,
      loadings: { ...state.loadings, organizationMonthlySummaryHours: true },
    };
  },
  [FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_SUCCESS]: (state, action) => {
    return {
      ...state,
      organizationMonthlySummaryHours: action.payload,
      error: null,
      loadings: { ...state.loadings, organizationMonthlySummaryHours: false },
    };
  },
  [FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_FAILED]: (state, action) => {
    return {
      ...state,
      organizationMonthlySummaryHours: [],
      error: action.payload,
      loadings: { ...state.loadings, organizationMonthlySummaryHours: false },
    };
  },
  [SEND_CURRENT_MONTH_HOURS_REPORT_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [SEND_CURRENT_MONTH_HOURS_REPORT_SUCCESS]: (state, action) => {
    return { ...state, error: null, loading: false };
  },
  [SEND_CURRENT_MONTH_HOURS_REPORT_FAILED]: (state, action) => {
    return { ...state, error: action.payload, loading: false };
  },
  [SEND_NEW_MONTH_HOURS_REPORT_START]: (state, action) => {
    return { ...state, error: null, loading: true };
  },
  [SEND_NEW_MONTH_HOURS_REPORT_SUCCESS]: (state, action) => {
    return { ...state, error: null, loading: false };
  },
  [SEND_NEW_MONTH_HOURS_REPORT_FAILED]: (state, action) => {
    return { ...state, error: action.payload, loading: false };
  },
  [FETCH_ORGANIZATION_USERS_START]: (state = initialState, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_ORGANIZATION_USERS_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      currentOrganizationUsers: action.payload.userIds,
      loading: false,
    };
  },
  [FETCH_ORGANIZATION_USERS_FAILED]: (state = initialState, action) => {
    return {
      ...state,
      error: true,
      loading: false,
    };
  },
};

const organizationReducer = (state = initialState, action) => {
  let fn = null;
  fn = action.type && reducerMap[action.type];
  return fn ? fn(state, action) : state;
};

export default organizationReducer;
