import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    minWidth: "20rem",
    maxWidth: "30rem",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  }
}));

export default function UserPopoverList() {
  const classes = useStyles();
  const organizationUserIds = useSelector(
    (state) => state.organizations.currentOrganizationUsers
  );
  const allUsers = useSelector((state) => state.users.users);

  const mapOrganizationUserIdsToUsers = useCallback(
    () => {
      return allUsers.filter((user) => organizationUserIds.indexOf(user.id) > -1)
      .sort((firstUser, secondUser) => (firstUser.displayName > secondUser.displayName) ? 1 
        : ((secondUser.displayName > firstUser.displayName) ? -1 : 0));
    },
    [allUsers, organizationUserIds]
  );

  return (
    <List className={classes.list}>
        {mapOrganizationUserIdsToUsers().map((user,index) => {
            return (
                <div>
                    <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={user.displayName}
                        secondary={
                        <React.Fragment>
                            <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            >
                            {user.email}
                            </Typography>
                        </React.Fragment>
                        }
                    />
                    </ListItem>

                    {index < (mapOrganizationUserIdsToUsers().length - 1) && 
                        <Divider component="li" />
                    }
                </div>
            );
            })}

    </List>
  );
}
