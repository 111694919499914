import { intersection } from "lodash";
import CreateAnnouncement from "../CreateAnnouncement/CreateAnnouncement";
import EditProfile from "../components/NavBar/components/EditProfile/EditProfile";
import Hours from "../Hours/Hours";
import Notifications from "../Notifications/Notifications";
import OrganizationDetails from "../Organizations/components/OrganizationDetails/OrganizationDetails";
import Organizations from "../Organizations/Organizations";
import ServiceDesk from "../ServiceDesk/ServiceDesk";
import authModule from "../services/AuthModule";
import SignOut from "../SignOut";
import EditProfilePage from "../TeamProfiles/components/ProfilePage/EditProfilePage/EditProfilePage";
import ViewProfilePage from "../TeamProfiles/components/ProfilePage/ViewProfilePage/ViewProfilePage";
import TeamProfiles from "../TeamProfiles/TeamProfiles";
import TimeOff from "../TimeOff/TimeOff";
import AddUser from "../Users/components/AddUser/AddUser";
import EditUser from "../Users/components/EditUser/EditUser";
import Users from "../Users/Users";
import { ROLE_NAMES } from "../utils/roles";
import ViewAnnouncement from "../ViewAnnouncement/ViewAnnouncement";

const ROUTES = [
  {
    path: "/",
    exact: true,
    component: Organizations,
    title: "Organizations",
  },
  {
    path: "/announcement",
    exact: true,
    component: CreateAnnouncement,
    roles: [ROLE_NAMES.Admin],
    title: "Publish a New Announcement",
  },
  {
    path: "/users",
    component: Users,
    exact: true,
    roles: [ROLE_NAMES.Admin],
    title: "Users",
  },
  {
    path: "/users/add",
    component: AddUser,
    roles: [ROLE_NAMES.Admin],
    title: "Add User",
  },
  {
    path: "/users/:id/edit",
    component: EditUser,
    roles: [ROLE_NAMES.Admin],
    title: "Edit User",
  },
  {
    path: "/edit-profile",
    component: EditProfile,
    title: "Edit Profile",
  },
  {
    path: "/team-profiles",
    component: TeamProfiles,
    exact: true,
    title: "Team Members",
  },
  {
    path: "/team-profiles/:id",
    component: ViewProfilePage,
    exact: true,
    title: "View Team Member Profile",
  },
  {
    path: "/team-profiles/:id/edit",
    component: EditProfilePage,
    roles: [ROLE_NAMES.Admin],
    title: "Edit Team Member Profile",
  },
  {
    path: "/organizations",
    component: Organizations,
    title: "Organizations",
  },
  {
    path: "/organization-details/:id",
    component: OrganizationDetails,
    title: "Organization Details",
  },
  {
    path: "/hours",
    component: Hours,
    roles: [ROLE_NAMES.Client],
    titile: "Hours",
  },
  {
    path: "/service-desk",
    component: ServiceDesk,
    roles: [ROLE_NAMES.Client],
    title: "Service Desk",
  },
  {
    path: "/time-off",
    component: TimeOff,
    title: "Holidays Calendar",
  },
  {
    path: "/notifications",
    component: Notifications,
    title: "Notifications",
  },
  {
    path: "/announcement/:id",
    exact: true,
    title: "View Announcement",
    component: ViewAnnouncement,
  },
  {
    path: "/signout",
    component: SignOut,
  },
];

const getAllowedRoutes = () => {
  const userRoles = authModule.getRoles();

  return ROUTES.filter(({ roles }) => {
    if (!roles) {
      return true;
    }

    return intersection(roles, userRoles).length > 0;
  });
};

export { ROUTES, getAllowedRoutes };
