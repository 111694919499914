export const usersReducerActionTypes = {
  FETCH_USERS_START: "FETCH_USERS_START",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILED: "FETCH_USERS_FAILED",

  FETCH_USER_START: "FETCH_USER_START",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILED: "FETCH_USER_FAILED",

  FETCH_USER_PROFILE_START: "FETCH_USER_PROFILE_START",
  FETCH_USER_PROFILE_SUCCESS: "FETCH_USER_PROFILE_SUCCESS",
  FETCH_USER_PROFILE_FAILED: "FETCH_USER_PROFILE_FAILED",
  CREATE_USER_PROFILE_START: "CREATE_USER_PROFILE_START",
  CREATE_USER_PROFILE_SUCCESS: "CREATE_USER_PROFILE_SUCCESS",
  CREATE_USER_PROFILE_FAILED: "CREATE_USER_PROFILE_FAILED",
  UPDATE_USER_PROFILE_START: "UPDATE_USER_PROFILE_START",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILED: "UPDATE_USER_PROFILE_FAILED",

  SET_SELECTED_USER: "SET_SELECTED_USER",
  CREATE_USER_START: "CREATE_USER_START",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILED: "CREATE_USER_FAILED",
  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
};

export const roleReducerActionTypes = {
  FETCH_ROLES_START: "FETCH_ROLES_START",
  FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
  FETCH_ROLES_FAILED: "FETCH_ROLES_FAILED",
};

export const ceTeamReducerActionTypes = {
  FETCH_CE_TEAM_MEMBERS_START: "FETCH_CE_TEAM_MEMBERS_START",
  FETCH_CE_TEAM_MEMBERS_SUCCESS: "FETCH_CE_TEAM_MEMBERS_SUCCESS",
  FETCH_CE_TEAM_MEMBERS_FAILED: "FETCH_CE_TEAM_MEMBERS_FAILED",
  UPDATE_CE_TEAM_MEMBER_START: "UPDATE_CE_TEAM_MEMBER_START",
  UPDATE_CE_TEAM_MEMBER_SUCCESS: "UPDATE_CE_TEAM_MEMBER_SUCCESS",
  UPDATE_CE_TEAM_MEMBER_FAILED: "UPDATE_CE_TEAM_MEMBER_FAILED",
  FETCH_CE_TEAM_USER_PROFILE_START: "FETCH_CE_TEAM_USER_PROFILE_START",
  FETCH_CE_TEAM_USER_PROFILE_FAILED: "FETCH_CE_TEAM_USER_PROFILE_FAILED",
  FETCH_CE_TEAM_USER_PROFILE_SUCCESS: "FETCH_CE_TEAM_USER_PROFILE_SUCCESS",
};

export const RESET_STORE = "RESET_STORE";

export const organizationReducerActionTypes = {
  FETCH_ORGANIZATIONS_START: "FETCH_ORGANIZATIONS_START",
  FETCH_ORGANIZATIONS_SUCCESS: "FETCH_ORGANIZATIONS_SUCCESS",
  FETCH_ORGANIZATIONS_FAILED: "FETCH_ORGANIZATIONS_FAILED",
  SET_SELECTED_ORGANIZATION: "SET_SELECTED_ORGANIZATION",
  FETCH_ALL_DATE_PERIODS_START: "FETCH_ALL_DATE_PERIODS_START",
  FETCH_ALL_DATE_PERIODS_SUCCESS: "FETCH_ALL_DATE_PERIODS_SUCCESS",
  FETCH_ALL_DATE_PERIODS_FAILED: "FETCH_ALL_DATE_PERIODS_FAILED",
  FETCH_ORGANIZATION_HOUR_SUMMARY_START:
    "FETCH_ORGANIZATION_HOUR_SUMMARY_START",
  FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS:
    "FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS",
  FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED:
    "FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED",
  FETCH_USER_ORGANIZATIONS_START: "FETCH_USER_ORGANIZATIONS_START",
  FETCH_USER_ORGANIZATIONS_SUCCESS: "FETCH_USER_ORGANIZATIONS_SUCCESS",
  FETCH_USER_ORGANIZATIONS_FAILED: "FETCH_USER_ORGANIZATIONS_FAILED",
  ASSIGN_USER_TO_ORGANIZATION_START:"ASSIGN_USER_TO_ORGANIZATION_START",
  ASSIGN_USER_TO_ORGANIZATION_SUCCESS:"ASSIGN_USER_TO_ORGANIZATION_SUCCESS",
  ASSIGN_USER_TO_ORGANIZATION_FAILED: "ASSIGN_USER_TO_ORGANIZATION_FAILED",
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START: "FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START",
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_SUCCESS: "FETCH_OA_ORGANIZATION_HOUR_SUMMARY_SUCCESS",
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_FAILED: "FETCH_OA_ORGANIZATION_HOUR_SUMMARY_FAILED",
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_START: "FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_START",
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_SUCCESS: "FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_SUCCESS",
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_FAILED: "FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_FAILED",
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_START: "APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_START",
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_SUCCESS: "APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_SUCCESS",
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_FAILED: "APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_FAILED",
  REQUEST_TO_BORROW_HOURS_START: "REQUST_BORROW_HOURS_START", 
  REQUEST_TO_BORROW_HOURS_SUCCESS: "REQUST_BORROW_HOURS_SUCCESS", 
  REQUEST_TO_BORROW_HOURS_FAILED: "REQUST_BORROW_HOURS_FAILED",
  REQUEST_ADDITIONAL_HOURS_START: "REQUEST_ADDITIONAL_HOURS_START",
  REQUEST_ADDITIONAL_HOURS_SUCCESS: "REQUEST_ADDITIONAL_HOURS_SUCCESS", 
  REQUEST_ADDITIONAL_HOURS_FAILED: "REQUEST_ADDITIONAL_HOURS_FAILED", 
  APPROVE_TO_BORROW_HOURS_START: "APPROVE_TO_BORROW_HOURS_START",
  APPROVE_TO_BORROW_HOURS_SUCCESS: "APPROVE_TO_BORROW_HOURS_SUCCESS",
  APPROVE_TO_BORROW_HOURS_FAILED: "APPROVE_TO_BORROW_HOURS_FAILED", 
  APPROVE_ADDITIONAL_HOURS_START: "APPROVE_ADDITIONAL_HOURS_START", 
  APPROVE_ADDITIONAL_HOURS_SUCCESS: "APPROVE_ADDITIONAL_HOURS_SUCCESS", 
  APPROVE_ADDITIONAL_HOURS_FAILED: "APPROVE_ADDITIONAL_HOURS_FAILED", 
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_START: "FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_START", 
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS: "FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS", 
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_FAILED: "FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_FAILED",
  SEND_CURRENT_MONTH_HOURS_REPORT_START: "SEND_CURRENT_MONTH_HOURS_REPORT_START",
  SEND_CURRENT_MONTH_HOURS_REPORT_SUCCESS: "SEND_CURRENT_MONTH_HOURS_REPORT_SUCCESS", 
  SEND_CURRENT_MONTH_HOURS_REPORT_FAILED: "SEND_CURRENT_MONTH_HOURS_REPORT_FAILED", 
  SEND_NEW_MONTH_HOURS_REPORT_START: "SEND_NEW_MONTH_HOURS_REPORT_START",
  SEND_NEW_MONTH_HOURS_REPORT_SUCCESS: "SEND_NEW_MONTH_HOURS_REPORT_SUCCESS", 
  SEND_NEW_MONTH_HOURS_REPORT_FAILED: "SEND_NEW_MONTH_HOURS_REPORT_FAILED",
  FETCH_ORGANIZATION_USERS_START: "FETCH_ORGANIZATION_USERS_START",
  FETCH_ORGANIZATION_USERS_SUCCESS: "FETCH_ORGANIZATION_USERS_SUCCESS",
  FETCH_ORGANIZATION_USERS_FAILED: "FETCH_ORGANIZATION_USERS_FAILED"
};

export const notificationsReducerActionTypes = {
  FETCH_ALL_NOTIFICATIONS_START: "FETCH_ALL_NOTIFICATIONS_START",
  FETCH_ALL_NOTIFICATIONS_SUCCESS: "FETCH_ALL_NOTIFICATIONS_SUCCESS",
  FETCH_ALL_NOTIFICATIONS_FAILED: "FETCH_ALL_NOTIFICATIONS_FAILED",
};

export const announcementsReducerActionTypes = {
  GET_ANNOUNCEMENT_START: "GET_ANNOUNCEMENT_START",
  GET_ANNOUNCEMENT_SUCCESS: "GET_ANNOUNCEMENT_SUCCESS", 
  GET_ANNOUNCEMENT_FAILED: "GET_ANNOUNCEMENT_FAILED", 
  PUBLISH_ANNOUNCEMENT_START: "PUBLISH_ANNOUNCEMENT_START",
  PUBLISH_ANNOUNCEMENT_SUCCESS: "PUBLISH_ANNOUNCEMENT_SUCCESS",
  PUBLISH_ANNOUNCEMENT_FAILED: "PUBLISH_ANNOUNCEMENT_FAILED",
};

export const generalReducerActionTypes = {
  SET_LOADER: "SET_LOADER",
  SET_ERROR: "SET_ERROR",
};

export const eventsReducerActionTypes = {
  FETCH_MONTHLY_EVENTS_START: "FETCH_MONTHLY_EVENTS_START",
  FETCH_MONTHLY_EVENTS_SUCCESS: "FETCH_MONTHLY_EVENTS_SUCCESS",
  FETCH_MONTHLY_EVENTS_FAILED: "FETCH_MONTHLY_EVENTS_FAILED",
};

export const uiReducerActionTypes = {
  SNACKBAR_SUCCESS: "SNACKBAR_SUCCESS",
  SNACKBAR_ERROR: "SNACKBAR_ERROR",
  SNACKBAR_CLEAR: "SNACKBAR_CLEAR",
};
