import React, { useState, useEffect, useCallback, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

import NotificationsIcon from "@material-ui/icons/Notifications";

import NotificationsPopoverList from "../NotificationsPopoverList/NotificationsPopoverList";

import { getAllNotifications, markNotificationAsSeen } from "../../../../store/actions/notificationsAction";
import { useInterval } from "../../../../utils/hooks/useInterval";

const NOTIFICATIONS_FEED_SIZE_INCREMENT = 50;
const NOTIFICATIONS_FEED_REFRESH_INTERVAL_MILLISECONDS = 120000;

export default function NotificationsPopover() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [notificationsToLoadCount, setNotificationsToLoadCount] = useState(5);

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const loadNotifications = useCallback(
    (showLoaders = false) => {
      dispatch(getAllNotifications(notificationsToLoadCount, showLoaders));
    },
    [dispatch, notificationsToLoadCount]
  );

  const newNotifications = useMemo(
    () =>
      notifications.filter((notification) => notification.seenAt === null)
        .length,
    [notifications]
  );

  // Fetch the notifications feed every 2 minutes
  useInterval(
    () => loadNotifications(),
    NOTIFICATIONS_FEED_REFRESH_INTERVAL_MILLISECONDS
  );

  const handleNotificationSeen = (notificationId) => {
    dispatch(markNotificationAsSeen(notificationId, notificationsToLoadCount));
    handleClose();
  };

  useEffect(() => {
    if (notifications.length === 0) {
      loadNotifications();
    }
  }, [loadNotifications, notifications.length]);

  // Fetch the notifications feed each time the notificationsToLoadCount changes
  useEffect(() => {
    loadNotifications(true);
  }, [loadNotifications]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoadMore = () => {
    setNotificationsToLoadCount(
      (currentCount) => currentCount + NOTIFICATIONS_FEED_SIZE_INCREMENT
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Tooltip title="Notifications" aria-label="Notifications">
        <IconButton color="inherit" onClick={handleClick}>
          <Badge badgeContent={newNotifications} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <NotificationsPopoverList
          notifications={notifications}
          handleClose={handleClose}
          handleLoadMore={handleLoadMore}
          handleNotificationSeen={handleNotificationSeen} />
      </Popover>
    </React.Fragment>
  );
}
