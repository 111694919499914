import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Typography,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    padding: theme.spacing( 2 ),
    color: theme.palette.text.primary,
  },
  info: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  message: {
    marginBottom: theme.spacing( 3 ),
  },
  button: {
    marginRight: theme.spacing( 1 ),
  },
} ) );

export default function Announcement( props ) {
  const classes = useStyles();
  const rootClassname = clsx(
    props.info && classes.info,
    props.warning && classes.warning,
    props.success && classes.success,
    props.error && classes.error,
    classes.root
  );

  return (
    <Paper className={rootClassname}>
      <Typography variant="h6" component="h3" color="inherit" gutterBottom>
        {props.title}
      </Typography>

      <div className={classes.message}>
        <Typography variant="subtitle1" component="p" color="inherit" gutterBottom>
          {props.message}
        </Typography>
      </div>

      <div>
        <Button className={classes.button} variant="contained" color="primary">
          To Be
        </Button>
        <Button className={classes.button} variant="contained">
          Not To Be
        </Button>
      </div>
    </Paper>
  );
}
