import { usersReducerActionTypes } from "../actionTypes";

const {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILED,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILED,
  CREATE_USER_PROFILE_START,
  CREATE_USER_PROFILE_SUCCESS,
  CREATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  SET_SELECTED_USER,
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
} = usersReducerActionTypes;

const initialState = {
  users: [],
  user: [],
  selectedUser: {},
  hasProfile: false,
  loading: null,
  error: null,
};

const reducerMap = {
  [FETCH_USERS_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_USERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      users: action.payload,
      loading: false,
    };
  },
  [FETCH_USERS_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  [FETCH_USER_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      user: action.payload,
      loading: false,
    };
  },
  [FETCH_USER_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  [FETCH_USER_PROFILE_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_USER_PROFILE_SUCCESS]: (state, action) => {
    return {
      ...state,
      user: action.payload,
      hasProfile: !!action.payload?.userId,
      loading: false,
    };
  },
  [FETCH_USER_PROFILE_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  [CREATE_USER_PROFILE_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [CREATE_USER_PROFILE_SUCCESS]: (state, action) => {
    return {
      ...state,
      user: action.payload,
      hasProfie: true,
      loading: false,
    };
  },
  [CREATE_USER_PROFILE_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  [UPDATE_USER_PROFILE_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [UPDATE_USER_PROFILE_SUCCESS]: (state, action) => {
    return {
      ...state,
      user: action.payload,
      loading: false,
    };
  },
  [UPDATE_USER_PROFILE_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      hasProfie: true,
      error: true,
    };
  },
  [SET_SELECTED_USER]: (state, action) => {
    return {
      ...state,
      selectedUser: action.payload,
      loading: false,
    };
  },
  [CREATE_USER_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [CREATE_USER_SUCCESS]: (state, action) => {
    const userToAdd = action.payload;
    return {
      ...state,
      loading: false,
      users: state.users.concat(userToAdd),
    };
  },
  [CREATE_USER_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  [UPDATE_USER_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [UPDATE_USER_SUCCESS]: (state, action) => {
    const index = state.users.findIndex(
      (user) => user.id === action.payload.id
    );
    const users = [...state.users];
    users[index] = { ...action.payload };

    return {
      ...state,
      users: [...users],
      loading: false,
    };
  },
  [UPDATE_USER_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  },
};

const userReducer = (state = initialState, action) => {
  let fn = null;
  fn = action.type && reducerMap[action.type];
  return fn ? fn(state, action) : state;
};

export default userReducer;
