import React, { useEffect } from "react";
import { intersection } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PeopleIcon from "@material-ui/icons/People";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import BusinessIcon from "@material-ui/icons/Business";
import { Announcement } from "@material-ui/icons";
import Logo from "../../../Logo/Logo";
import authModule from "../../../../services/AuthModule";
import NavDrawerItem from "./NavDrawerItem";
import { ROLE_NAMES } from "../../../../utils/roles";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrganizationsByUserId } from "../../../../store/actions/organizationAction";

const NAV_ITEMS = (organizationIds) => [
  {
    linkTo: "/users",
    icon: <PeopleOutlineIcon />,
    text: "Users",
    roles: [ROLE_NAMES.Admin],
  },
  {
    linkTo: "/organizations",
    icon: <BusinessIcon />,
    text: "Organizations",
    roles: [ROLE_NAMES.Admin, ROLE_NAMES.CETeamMember],
  },
  {
    linkTo: organizationIds?.length !== 1 ? "/organizations" : `/organization-details/${organizationIds[0]}`,
    icon: <AccessTimeIcon />,
    text: "Hours Details",
    roles: [ROLE_NAMES.Client],
  },
  {
    linkTo: "/team-profiles",
    icon: <PeopleIcon />,
    text: "Team Members"
  },
  {
    linkTo: "/time-off",
    icon: <CalendarTodayIcon />,
    text: "Holidays Calendar",
  },
  {
    linkTo: "/announcement",
    icon: <Announcement />,
    text: "Announcement",
    roles: [ROLE_NAMES.Admin],
  },
];

const getNavItems = (organizationIds) => {
  const userRoles = authModule.getRoles();

  return NAV_ITEMS(organizationIds).filter(({ roles }) => {
    if (!roles) {
      return true;
    }

    return intersection(roles, userRoles).length > 0;
  });
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

export default function NavDrawer() {
  const classes = useStyles();
  const organizationIds = useSelector(
    (state) => state.organizations.currentUserOrganizations.organizationsIds
  );
  const navItems = getNavItems(organizationIds);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    if (authModule.getRoles().some((role) => role === ROLE_NAMES.Client)) {
      dispatch(getAllOrganizationsByUserId(user.userId));
    }
  }, [dispatch, user.userId]);

  return (
    <>
      <div className={classes.toolbarIcon}>
        <Logo />
      </div>
      <Divider />

      <List>
        {navItems.map((item, index) => (
          <NavDrawerItem
            key={index}
            linkTo={item.linkTo}
            icon={item.icon}
            text={item.text}
          />
        ))}
      </List>
    </>
  );
}
