import React, { useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Button,
    Typography
} from '@material-ui/core';

import logo from '../Logo/mentormate-logo.svg';
import authModule from '../../services/AuthModule';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles((theme) => createStyles({
    content: {
        display: 'flex',
    },
    toolbarContent: {
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderStyle: 'solid'
    },
    toolbarIcon: {
        maxWidth: 300,
    },
    buttonText: {
        letterSpacing: 2,
        fontWeight: 500,
    },
    button: {
        size: 'large',
        marginTop: '1rem',
        padding: '5px 15px',
        borderRadius: '3px',
        backgroundColor: theme.palette.primary.main,
        content: 'white',
      '&:hover': {
            backgroundColor: theme.palette.text.primary,
            boxShadow: '0 0 11px rgba(33,33,33,.2)'
        }
    },
    mainContent: {
        height: '100vh',
        padding: '70px 0',
        textAlign: 'center',
        backgroundColor: '#f5f6fa',
        ...theme.mixins.toolbar
    },
    tittle: {
        letterSpacing: 2,
        fontWeight: 400,
        fontSize: '3rem',
        paddingBottom: '0rem',
    },
    text: {
        fontSize: '1rem',
        margin: '0px',
    }
}));

const LandingPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: "SET_LOADER", payload: false});
    }, [dispatch]);

    const popLogin = async () => {
        dispatch({type: "SET_LOADER", payload: true});
        await authModule.login(() => dispatch({type: "SET_LOADER", payload: false}));
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar className={classes.toolbarContent}>
                    <img src={logo} alt="Mentormate" className={classes.toolbarIcon} />
                    <Button size="large" onClick={async () => await popLogin()} >
                        <Typography variant="h5" color="primary" className={classes.buttonText}>
                            Login
                        </Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.mainContent}>
                <Typography color="primary" className={classes.tittle}>
                    Client Portal
                </Typography>
                <p className={classes.text}>Welcome to the place where you can track and manage your monthly hours.</p>
                <p className={classes.text}>Enjoy your MentorMate journey!</p>
                <Button className={classes.button} onClick={async () => await popLogin()} >
                    <Typography variant="h6" style={{ color: 'white' }} className={classes.buttonText}>
                        Login
                        </Typography>
                </Button>
            </div>
        </>
    )
}

export default LandingPage;
