import React, { useEffect, useState } from "react";
import BigCalendar from "./components/BigCalendar/BigCalendar";

import { useDispatch, useSelector } from "react-redux";

import { getMonthlyEvents } from "../store/actions/eventsAction";

function parseEventData(eventsResponse) {
  if (!eventsResponse?.events?.days?.length) {
    return [];
  }

  const eventsByTitle = new Map();

  eventsResponse.events.days.forEach((day) => {
    day.events.forEach((rawEvent) => {
      const parsedEvent = {
        start: new Date(
          eventsResponse.events.year,
          eventsResponse.events.month - 1,
          day.day
        ),
        end: new Date(
          eventsResponse.events.year,
          eventsResponse.events.month - 1,
          day.day + 1
        ),
        title: `${rawEvent.name}`,
        type: rawEvent.type,
        userId: rawEvent.id
      };

      if (eventsByTitle.has(parsedEvent.title) === false) {
        eventsByTitle.set(parsedEvent.title, [parsedEvent]);
      } else {
        // There is an existing group of events with the same title
        const group = eventsByTitle.get(parsedEvent.title);
        const lastEventInGroup = group[group.length - 1];

        // Check if the current event is spanning multiple consequtive days. For example, a PTO from Monday to Wednesday
        if (lastEventInGroup.end.getTime() === parsedEvent.start.getTime()) {
          // The current event is just a continuation of the big event chain.
          // Increment the end date of the event object that marks the beginning of the chain
          lastEventInGroup.end.setDate(lastEventInGroup.end.getDate() + 1);
        } else {
          // The event is not a continuation of an event chain, but an individual event. Insert it.
          // Note that Map preserves the insert order
          group.push(parsedEvent);
        }
      }
    });
  });

  // Create an array from the map values and flat it, as each value is an array
  return Array.from(eventsByTitle.values()).flat();
}

export default function TimeOff() {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    dispatch({ type: "SET_LOADER", payload: true });
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    dispatch(getMonthlyEvents(month, year));
  }, [dispatch, date]);

  const selectorEvents = useSelector((state) => state.events);

  return (
    <div>
      <BigCalendar events={parseEventData(selectorEvents)} setDate={setDate} />
    </div>
  );
}
