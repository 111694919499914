import axios from "axios";
import authModule from "../services/AuthModule";
import * as snackbarActions from "../store/actions/snackbarActions";

export const setactionTypeBase = (actionType) => {
  const statuses = ["START", "SUCCESS", "FAILED"];
  const splitActionType = actionType.split("_");

  if (statuses.indexOf(splitActionType[splitActionType.length - 1]) > -1) {
    return splitActionType.slice(0, -1).join("_");
  }

  return actionType;
};

async function getAccessToken() {
  const accessToken = await authModule.getTokenPopup();
  return accessToken;
}

export const REQUEST_TYPES = {
  GET: "get",
  POST: "post",
};

export const callApiEndpoint = async ({
  dispatch,
  endpoint,
  actionTypes,
  requestType = REQUEST_TYPES.POST,
  token = getAccessToken(),
  data = {},
  contentType = "",
  returnPath = "",
  message = "",
  payload = {},
  timeout = 30000,
  useGlobalLoader = true,
  returnEmptyOn404 = false
}) => {
  //TODO: include other req types or create a new function that will deal with those
  if (requestType !== REQUEST_TYPES.POST && requestType !== REQUEST_TYPES.GET) {
    return Error("request type is not formed well");
  }
  const innerActionType = setactionTypeBase(actionTypes);
  dispatch({ type: `${innerActionType}_START` });
  useGlobalLoader && dispatch({ type: "SET_LOADER", payload: true });

  return await axios({
    url: endpoint,
    method: requestType,
    timeout: timeout,
    headers: {
      Authorization: `Bearer ${await token} `,
      "content-type": contentType,
    },
    data: data,
  })
    .then((response) => {
      if (requestType === REQUEST_TYPES.POST) {
        dispatch({
          type: `${innerActionType}_SUCCESS`,
          payload: payload,
        });
      } else {
        dispatch({
          type: `${innerActionType}_SUCCESS`,
          payload: response.data,
          requestData: data,
        });
      }
      if (message !== "") {
        dispatch(snackbarActions.showSuccessSnackbar(`${message}`));
      }
      useGlobalLoader && dispatch({ type: "SET_LOADER", payload: false });
      if (returnPath !== "") {
        window.location.href = returnPath;
      }
    })
    .catch((error) => {
      if (error.response.status === 404 && returnEmptyOn404) {
        dispatch({
          type: `${innerActionType}_SUCCESS`,
          payload: {},
          requestData: data,
        });
        useGlobalLoader && dispatch({ type: "SET_LOADER", payload: false });
      } else {
        dispatch({
          type: `${innerActionType}_FAILED`,
          payload: error.response,
          requestData: data,
        });
        useGlobalLoader && dispatch({ type: "SET_LOADER", payload: false });
        dispatch(
          snackbarActions.showErrorSnackbar(error?.response?.data?.messages)
        );
      }
    });
};

export const callApiEndpointWithoutCatch = async ({
  dispatch,
  endpoint,
  actionTypes,
  requestType = REQUEST_TYPES.POST,
  token = getAccessToken(),
  data = {},
  contentType = "",
  returnPath = "",
  message = "",
  payload = {},
  timeout = 5000,
}) => {
  //TODO: include other req types or create a new function that will deal with those
  if (requestType !== REQUEST_TYPES.POST && requestType !== REQUEST_TYPES.GET) {
    return Error("request type is not formed well");
  }
  const innerActionType = setactionTypeBase(actionTypes);
  dispatch({ type: `${innerActionType}_START` });
  dispatch({ type: "SET_LOADER", payload: true });

  return await axios({
    url: endpoint,
    method: requestType,
    timeout: timeout,
    headers: {
      Authorization: `Bearer ${await token} `,
      "content-type": contentType,
    },
    data: data,
  })
    .then((response) => {
      if (requestType === REQUEST_TYPES.POST) {
        dispatch({
          type: `${innerActionType}_SUCCESS`,
          payload: payload,
        });
      } else {
        dispatch({
          type: `${innerActionType}_SUCCESS`,
          payload: response.data,
          requestData: data,
        });
      }
      if (message !== "") {
        dispatch(snackbarActions.showSuccessSnackbar(`${message}`));
      }
      if (returnPath !== "") {
        window.location.href = returnPath;
      }
    })
    .finally(() => {
      dispatch({ type: "SET_LOADER", payload: false });
    });
};

export const callApiEndpointNoMessages = async ({
  endpoint,
  requestType = "post",
  token = getAccessToken(),
  data = {},
  contentType = "",
  timeout = 5000,
}) => {
  return axios({
    url: endpoint,
    method: requestType,
    timeout: timeout,
    headers: {
      Authorization: `Bearer ${await token} `,
      "content-type": contentType,
    },
    data: data,
  });
};
