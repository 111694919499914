import React from "react";
import Grid from "@material-ui/core/Grid";
import { Paper, Box, TextField } from "@material-ui/core/";
import ProfileCard from "../ProfileCard/ProfileCard";
import NotFoundMessage from "../../../components/NotFoundMessage/NotFoundMessage";
import SearchIcon from "@material-ui/icons/Search";

const MainContent = (props) => {
  const isEmpty = props.ceTeamMembers.length === 0;

  if (isEmpty) {
    return (
      <Box p={2} pb={0}>
        <NotFoundMessage icon={<SearchIcon />} message="No matches found" />
      </Box>
    );
  }
  return (
    <Box p={2} pb={0}>
      <Grid container spacing={3}>
        {props.ceTeamMembers.map((member) => (
          <Grid item xs={12} md={6} lg={3} key={member.userId}>
            <ProfileCard
              id={member.userId}
              title={member.displayName}
              name={member.displayName}
              skills={member.skills}
              interests={member.interests}
              image={member.imageURL}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const TeamProfilesList = (props) => {
  return (
    <Paper>
      <Box p={2} pb={0}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          value={props.input}
          onChange={props.onChange}
          onKeyUp={(event) => {
            if (event.key === "Escape") {
              props.setInput("");
            }
          }}
          fullWidth
        />
      </Box>
      <MainContent ceTeamMembers={props.ceTeamMembers} />
    </Paper>
  );
};

export default TeamProfilesList;
