import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
const loaderStyle = {
	backgroundColor: "rgba(255, 255, 255, 0.7)",
  width: "100%",
  height: '100%',
  top: '0px',
  left: '0px',
  position: 'fixed',
  display: 'inline-block',
  zIndex: '9999',
}

const Loader = (isLoading) => {
  return(
    <>
      {
        isLoading.isLoading
        &&
        <div style={loaderStyle}>
          <CircularProgress />
        </div>
      }
      
    </>
  )
}

export default Loader;