import React from 'react';

import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import NotificationTypeIcon from './../../../components/NotificationTypeIcon/NotificationTypeIcon';

import { formatISO, formatDistanceToNowStrict } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  timestamp: {
    marginRight: 'auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  }
}));

export default function NotificationPanel({ id, title, timestamp, seen, routingDetails }) {
  const classes = useStyles();
  let route = '/';

  switch (routingDetails.location) {
    case "Announcments": route = 'annoucement';
      break;
    case "Organization": route = 'organizations';
      break;
    case "OrganizationPage": route = 'organizations';
      break;
    case "Dashboard": route = '';
      break;
    default: route = '';
  }

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        id={`notification-header-${id}`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar>
              <NotificationTypeIcon />
            </Avatar>
          </Grid>
          <Grid item xs zeroMinWidth className={classes.title}>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat dignissimos cumque repellat.
        Quae asperiores, delectus repellat quod soluta est illum rem?
        Nisi corporis quisquam doloremque iste harum eveniet, culpa aspernatur saepe a, excepturi illo assumenda et ea recusandae.
        Officia, tenetur nemo! Corporis optio corrupti tempora est? Facilis quo corrupti aspernatur.
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Typography color="textSecondary" variant="caption">
          <time dateTime={formatISO(new Date(timestamp))}>
            {formatDistanceToNowStrict(new Date(timestamp))} ago
          </time>
        </Typography>
        <Button size="small" color="primary" component={Link} to={`/${route}`}>
          Go to {routingDetails.location}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}

