import React from "react";
import { Formik, Form, Field } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as yup from "yup";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import ButtonWithAlert from "../../../components/ButtonWithAlert/ButtonWithAlert";

var hoursReqestValidationSchema = function(hoursMultipleOf, maxApprovableHours) {
  return yup.object({
    hours: yup
      .number("Please provide a valid number")
      .integer("Please provide an integer")
      .nullable()
      .min(1, "The minimum number of hours is 1")
      .max(maxApprovableHours, `The maximum number of hours is ${maxApprovableHours}`)
      .multipleOf(hoursMultipleOf, "Value must be a multiple of ", hoursMultipleOf)
      .required("Please provide a number"),
  });
};

const useStyles = makeStyles((theme) => ({
  modal: {
    paddingBottom: "10px",
  },
  hoursText: {
    lineHeight: "1.95",
  },
  notesStyles: {
    display: "block"
  },
}));

const ManageHoursForm = ({
  initialHours,
  requestedHours,
  approvedHours,
  maxApprovableHours,
  label = "Hours *",
  hoursMultipleOf = 1,
  notesLabel = "Notes",
  provideNotesInput = false,
  buttonText,
  modalTitle,
  explicitConsentText,
  handleSubmit,
  className,
  validationSchema = hoursReqestValidationSchema(hoursMultipleOf, maxApprovableHours),
  requireExplicitConsent = false,
  disabled = false,
  buttonSize = "small",
  buttonVariant = "outlined",
  buttonColor = "primary",
}) => {
  const classes = useStyles();

  const handleFormSubmit = (nativeHandler, setSubmitting) => {
    nativeHandler();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        hours: initialHours || undefined,
        isConsentGranted: false,
        notes: ''
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values, errors, handleSubmit, setSubmitting }) => (
        <ButtonWithAlert
          buttonText={buttonText}
          buttonSize={buttonSize}
          buttonVariant={buttonVariant}
          buttonColor={buttonColor}
          mainButtonDisabled={disabled}
          onConfirm={() => handleFormSubmit(handleSubmit, setSubmitting)}
          confirmButtonDisabled={
            disabled ||
            isSubmitting ||
            !!errors.hours ||
            (requireExplicitConsent && values.isConsentGranted === false)
          }
          alertTitle={modalTitle}
          alertClassName={classes.modal}
        >
          <>
            {!!requestedHours && (
              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.hoursText}
              >
                {requestedHours} requested
              </Typography>
            )}
            {!!approvedHours && (
              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.hoursText}
              >
                {approvedHours} approved
              </Typography>
            )}
            {!!maxApprovableHours && (
              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.hoursText}
              >
                {maxApprovableHours} maximum
              </Typography>
            )}
            <Form className={className}>
              <Field
                name="hours"
                type="number"
                component={TextField}
                label={label}
                size="small"
                helperText=" " // Display an empty helper text to ensure there is reserved space for the error text, so the components don't flicker and break the design
                disabled={disabled || isSubmitting}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              {requireExplicitConsent && (
                <Field
                  type="checkbox"
                  component={CheckboxWithLabel}
                  name="isConsentGranted"
                  Label={{ label: explicitConsentText }}
                  color="primary"
                />
              )}
              {provideNotesInput && (
                <Field
                  as="textarea"
                  name="notes"
                  className={classes.notesStyles}
                  component={TextField}
                  multiline
                  fullWidth
                  rows="3"
                  label={notesLabel}
                  size="small"
                  disabled={disabled || isSubmitting}
                  margin="normal"
                  variant="outlined"
                />
              )}
            </Form>
          </>
        </ButtonWithAlert>
      )}
    </Formik>
  );
};

export default ManageHoursForm;
