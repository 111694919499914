import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Typography,
} from '@material-ui/core';

import TelegramIcon from '@material-ui/icons/Telegram';

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    padding: theme.spacing( 6 ),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
} ) );

const defaultMsg = 'There is no content in this section yet.';

export default function EmptyState( props ) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TelegramIcon fontSize="large" color="inherit"></TelegramIcon>
      <Typography variant="subtitle1" component="p" color="inherit">
        {props.message !== undefined ? props.message : defaultMsg}
      </Typography>
    </Paper>
  );
}
