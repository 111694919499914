import React from 'react';

import Grid from '@material-ui/core/Grid';

import TicketsSummary from './components/TicketsSummary/TicketsSummary';
import Announcement from './../components/Announcement/Announcement';
import EmptyState from './../components/EmptyState/EmptyState';

// TODO: Wire this to real data
const isEmpty = false;

export default function ServiceDesk() {

  if (isEmpty) {
    return (<EmptyState></EmptyState>);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Announcement
          title="Visit the Service Desk"
          message="This way please..."
        />
      </Grid>
      <Grid item xs={12}>
        <TicketsSummary />
      </Grid>
    </Grid>
  );
}
