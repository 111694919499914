import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Box, Button,List, ListItemIcon,
  ListItemText,
  Checkbox,ListItem } from '@material-ui/core/';
import { TextField, RadioGroup } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import { FormControlLabel, Radio, FormLabel,CircularProgress } from '@material-ui/core';
import { assignUserToOrganization, getAllOrganizationsByUserId } from "../../../store/actions/organizationAction";
import { uniqBy } from "lodash";

import Title from './../../../components/Title/Title';
import { updateUser } from '../../../store/actions/userAction';
import { UserValidationSchema } from '../../../validations/schemas/UserValidationSchema';
import { Label } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '100%',
    height: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  list:{
    display:"grid",
    gridTemplateColumns:"auto auto auto",
  },
  inactive: {
    color: theme.palette.action.disabled,
    fontStyle: "italic",
  }
}));

const EditUser = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  
  const user = useSelector((state) => state.users.selectedUser);
  const roles = useSelector((state) => state.roles.roles);
  const organizations  = useSelector((state) => state.organizations.organizations);
  const currentUserOrganizations = useSelector(state => state.organizations.currentUserOrganizations);
  const loading = useSelector((state) => state.organizations.loading);

  useEffect(() => {
    dispatch(getAllOrganizationsByUserId(props.match.params.id));
  },[dispatch,props.match.params.id]);

  const handleToggle = (newOrgId) => () => {
    const organizationsIds = currentUserOrganizations.organizationsIds;

    if(!organizationsIds.some(x => x === newOrgId)){
      dispatch(assignUserToOrganization(currentUserOrganizations.userId,[...organizationsIds,newOrgId]));
     } 
    else {
      const newOrganizationsId = organizationsIds.filter(x => x !== newOrgId);
      dispatch(assignUserToOrganization(currentUserOrganizations.userId,[...newOrganizationsId]));
    }
  };

   const isChecked = (id) => {
    const result = currentUserOrganizations?.organizationsIds?.some(x => x === id);

    return result;
   }
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={8}>
        <Paper className={classes.paper}>
          <Formik
            initialValues={{
              id: user.id,
              email: user.email,
              displayName: user.displayName,
              isSuspended: user.isSuspended ? 'yes' : 'no',
              roles: user.roles,
            }}
            onSubmit={(values, { setSubmitting }) => {
              try {
                values.isSuspended =
                  values.isSuspended === 'yes' ? true : false;
                dispatch(updateUser(values));
              } catch (ok) {}

              setSubmitting(false);
            }}
            validationSchema={UserValidationSchema}
          >
            {({ submitForm, isSubmitting, touched, errors }) => (
                  <Form>
                    <Title>{user.email}</Title>
                    <Field
                      as="span"
                      name="email"
                      type="email"
                      label="Email"
                      required
                      fullWidth
                      margin="normal"
                      disabled
                />
                <Field
                  component={TextField}
                  name="displayName"
                  type="text"
                  label="Profile Name"
                  required
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={Autocomplete}
                  name="roles"
                  multiple
                  options={roles}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  filterSelectedOptions
                  fullWidth
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Roles"
                      margin="normal"
                      error={touched['roles'] && !!errors['roles']}
                      helperText={touched['roles'] && errors['roles']}
                      variant="outlined"
                    />
                  )}
                />
                <FormLabel component="legend">
                  Suspend User:
                  <Field
                    component={RadioGroup}
                    aria-label="Suspend User"
                    name="isSuspended"
                    style={{ display: 'inline-block', padding: '0 15px' }}
                    value={user.isSuspended}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </Field>
                </FormLabel>
                <Box pt={4}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Update
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => {
                      window.location.href = '/users';
                    }}
                  >
                    Cancel
                  </Button>
                </Box><br />

                <List className={classes.list}>
                      {uniqBy(organizations, "id").map((org,index) => {
                    return (
                      <ListItem key={index} dense button onClick={handleToggle(org.id)}>
                        <ListItemIcon>
                          <Checkbox
                            color="primary"
                            edge="start"
                            checked={isChecked(org.id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': org.id }}
                          />
                        </ListItemIcon>

                        <ListItemText id={org.name} primary={org.name} className={(!org.isActive) ? classes.inactive : null} />
                      </ListItem>
                    );
                  })}
                </List>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditUser;
