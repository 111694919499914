import React from "react";

import Loader from "./components/Loader";
import ErrorState from "./components/ErrorState";
import LandingPage from "./components/LandingPage/LandingPage";
import Snackbar from "./components/Snackbars/Snackbar";
import "./App.scss";

import Main from "./components/Main/Main";
import NavBar from "./components/NavBar/NavBar";

import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./muiTheme.js";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

function App() {
  const { inProgress } = useMsal();

  // If an opperation that changes the account state (i.e login and logout) is in progress
  const isAccountStateChanging = inProgress === InteractionStatus.Login || inProgress === InteractionStatus.Logout;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Snackbar />
      <BrowserRouter>
        {/* <Loader /> */}
        <ErrorState />
        <AuthenticatedTemplate>
          {isAccountStateChanging ? null : ( // Avoid rendering content that requires account details during login and logout 
            <div className="App">
              <NavBar />
              <Main />
            </div>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LandingPage />
        </UnauthenticatedTemplate>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
