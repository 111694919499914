import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import NotificationListItem from './../../../NotificationListItem/NotificationListItem';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: '36rem',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: 'large',
  },
  link: {
    color: theme.palette.text.secondary,
    fontSize: 'large',
  }
}));

export default function NotificationsPopoverList({ notifications, handleClose, handleLoadMore, handleNotificationSeen }) {
  const classes = useStyles();

  return (
  <>
    {
        notifications?.length === 0 ? (
          <List className={classes.list}>
            <ListSubheader disableSticky>No notifications to be shown</ListSubheader>
          </List>
      ): (
            <List className={classes.list}> 

              <ListSubheader disableSticky className={ classes.header }>Notifications</ListSubheader>
              <Divider component="li" />
              {notifications.map((notification) => (
                notification.seenAt === null ?
                  <NotificationListItem
                    key={notification.id}
                    id={notification.id}
                    title={notification.title}
                    timestamp={notification.createdAt}
                    path={notification.routingDetails.location}
                    handleClose={handleClose}
                    handleNotificationSeen={handleNotificationSeen}
                    isSeen={false}
                  /> : null
              ))}
              {notifications.map((notification) => (
                notification.seenAt !== null ?
                  <NotificationListItem
                    key={notification.id}
                    id={notification.id}
                    title={notification.title}
                    timestamp={notification.createdAt}
                    path={notification.routingDetails.location}
                    handleClose={handleClose}
                    handleNotificationSeen={handleNotificationSeen}
                    isSeen={true}
                  /> : null
              ))}
              <Divider component="li" />
              <ListItem>
                <Box pt={1}>
                  <Link
                    component={RouterLink}
                    onClick={handleLoadMore}
                    className={classes.link}>
                      Load more
                  </Link>
                </Box>
              </ListItem>
            </List>
          )
      }
    </>
  );
}
