import React from 'react';

import NotificationsIcon from '@material-ui/icons/Notifications';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { NOTIFICATION_ROUTING_LOCATIONS } from "../../utils/notificationRoutingLocations";

export default function NotificationTypeIcon(props) {
  switch (props.type) {
    case NOTIFICATION_ROUTING_LOCATIONS.organizationDetails:
      return <AccessTimeIcon />;
    case NOTIFICATION_ROUTING_LOCATIONS.teamCalendar:
      return <CalendarTodayIcon />;
    default:
      return <NotificationsIcon />;
  }
}
