import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { ROLE_NAMES } from "../../../utils/roles";

import authModule from "../../../services/AuthModule";

const useStyles = makeStyles((theme) => ({
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  chips: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
  chipOnPTO: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '& .MuiChip-icon': {
      color: 'inherit',
    },
  },
}));

const ProfileCard = (props) => {
  const classes = useStyles();
  let isAdmin = authModule.isInRole(ROLE_NAMES.Admin);

  return (
    <Card className={classes.root}>
      <ButtonBase
          className={classes.cardAction}
          component={RouterLink}
          to={`/team-profiles/${props.id}`}
      >
      <CardActionArea>
        {props.image && (
          <CardMedia
            className={classes.media}
            image={props.image}
            title={props.title}
          />
        )}
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {props.name}
          </Typography>
          {!!props.skills.trim() && (
            <div>
              <Typography variant="body2" color="textPrimary" component="span">
                {"Skills: "}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                gutterBottom
              >
                {props.skills.length > 50
                  ? props.skills.slice(0, 50) + " ..."
                  : props.skills}
              </Typography>
            </div>)}
          {!!props.interests.trim() && (<div>
            <Typography variant="body2" color="textPrimary" component="span">
              {"Interests: "}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="span">
              {props.interests}
            </Typography>
          </div>)}
      </CardContent>
      <CardActions>
        { isAdmin &&
          <Button
            size="small"
            color="primary"
            component={RouterLink}
            to={`/team-profiles/${props.id}/edit`}
          >
            Edit
        </Button> }
      </CardActions>
      </ButtonBase>
    </Card>
  );
};

export default ProfileCard;
