import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import {
  Paper,
  Typography,
} from '@material-ui/core';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const loaderStyle = {
	backgroundColor: "rgba(255, 255, 255, 1)",
  width: "100%",
  height: '100%',
  top: '0px',
  left: '0px',
  position: 'fixed',
  display: 'inline-block',
  zIndex: '9999',
}

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    padding: theme.spacing( 6 ),
    textAlign: 'center',
    color: theme.palette.error.dark,
  },
  message: {
    marginBottom: theme.spacing( 2 ),
  },
} ) );

const defaultMsg = 'An error has occured.';

export default function ErrorState( props ) {
  const classes = useStyles();
  const isError = useSelector( state => (state.general.error));
  let history = useHistory();
  const dispatch = useDispatch();

  const redirect = () => {
    history.push("/");
    dispatch({ type: "SET_ERROR", payload: false });
    dispatch({ type: "RESET_STORE" });
  }

  return (
    <>
      {
        isError
        &&
        <div style={loaderStyle}>
          <Paper className={classes.root}>
            <ErrorOutlineIcon fontSize="large" color="inherit"></ErrorOutlineIcon>
            <Typography variant="subtitle1" component="p" color="inherit" className={classes.message}>
              {props.message !== undefined ? props.message : defaultMsg}
            </Typography>
            <Button variant="contained" onClick={ redirect }>Try Again</Button>
          </Paper>
        </div>
        
      }
    </>
    
  );
}
