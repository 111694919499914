import randomInt from 'random-int';

export default [
  {
    id: 0,
    status: 'In Progress',
    timestamp: 1592909138000,
    title: 'Meow-star team assembled!',
    content: 'Pellentesque facilisis. Nulla imperdiet sit amet magna. \nVestibulum dapibus, mauris nec malesuada fames ac turpis velit, rhoncus eu, luctus et interdum adipiscing wisi.',
    actions: null,
    participants: randomInt(1,6),
  },
  {
    id: 1,
    status: 'Resolved',
    timestamp: 1592826338000,
    title: 'Upcoming PTO for Milen Lambev',
    content: 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque dui, non felis. Maecenas malesuada elit lectus felis, malesuada ultricies.',
    actions: ['Go to schedule'],
    participants: randomInt(1,6),
  },
  {
    id: 2,
    status: 'Waiting for Client',
    timestamp: 1592307938000,
    title: 'Target hours reached',
    content: '',
    actions: ['Go to hours'],
    participants: randomInt(1,6),
  },
  {
    id: 0,
    status: 'New',
    timestamp: 1561290338000,
    title: 'Long ago in a distant land...',
    content: null,
    actions: null,
    participants: randomInt(1,6),
  },
];

export const earlier = [
];
