import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

// Reducers
import generalReducer from "./generalReducer";
import userReducer from "./userReducer";
import roleReducer from "./roleReducer";
import organizationReducer from "./organizationReducer";
import ceTeamReducer from "./ceTeamReducer";
import notificationsReducer from "./notificationsReducer";
import uiReducer from "./uiReducer";
import eventsReducer from "./eventsReducer";
import announementReducer from "./announcementReducer";

// Actions
import * as actionTypes from "../actionTypes";

const appReducer = combineReducers({
  general: generalReducer,
  users: userReducer,
  roles: roleReducer,
  organizations: organizationReducer,
  ceTeamMembers: ceTeamReducer,
  notifications: notificationsReducer,
  announcements: announementReducer,
  events: eventsReducer,
  ui: uiReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.RESET_STORE) {
    storage.removeItem("persist:root");
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
