import React from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Multiple select component. Tightly coupled with Formik.
// How to use:
// Create a Formik.Field and provide MultipleSelect as the value for the component prop.
// Example:
// <Field
// name="roles" // Required
// margin="normal"
// component={MultipleSelect} // Required
// multiple
// options={rolesOptions} // Required
// label="Roles *"
// disabled={false}
// allOptionName="all" /> // Required only if an "All" option is supported. Case sensitive.

const MultipleSelect = ({ allOptionName, ...props }) => {
  const {
    form: { setFieldValue, setFieldTouched, getFieldMeta },
    field: { name, value: fieldValue },
    getOptionLabel,
    selectedOptions,
    fiexdOptions,
  } = props;

  const { touched, error } = getFieldMeta(name);
  const hasError = touched && !!error;
  const hasAllOption = !!allOptionName;

  let allSelected = false;
  if (hasAllOption) {
    allSelected = fieldValue.some((option) => option.name === allOptionName);
  }

  return (
    <Autocomplete
      {...props}
      multiple
      name={name}
      value={fieldValue}
      getOptionLabel={getOptionLabel || ((option) => option.name)}
      defaultValue={selectedOptions}
      filterSelectedOptions
      onChange={(_, value) => {
        if (hasAllOption) {
          const filtered = value.filter(
            (option) => option.name === allOptionName
          );

          value = filtered.length > 0 ? filtered : value;
        }

        setFieldValue(name, value);
      }}
      getOptionDisabled={() => allSelected}
      onBlur={() => setFieldTouched(name, true, true)}
      renderTags={(tagValue, getTagProps) => {
        if (hasAllOption) {
          const filtered = tagValue.filter(
            (option) => option.name === allOptionName
          );

          tagValue = filtered.length > 0 ? filtered : tagValue;
        }

        return tagValue.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            disabled={props.disabled || fiexdOptions?.find(option)}
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          margin="normal"
          variant="outlined"
          error={hasError}
          helperText={hasError && error}
        />
      )}
    />
  );
};

export default MultipleSelect;
