import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Colophon() {
  return (
    <div>
      <Typography variant="body2" color="textSecondary" align="center">
        Continuation Engineering Portal
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © MentorMate '}
        {new Date().getFullYear()}
      </Typography>
    </div>
  );
}
