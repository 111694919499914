import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Formik
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

// Actions
import {
  updateCurrentUserProfile,
  createCurrentUserProfile,
} from "../../../../store/actions/userAction";

// Material UI component imports
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "100%",
    height: "300px",
    marginBottom: "5px",
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

export default function EditProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.users.user);
  const hasProfile = useSelector((state) => state.users.hasProfile);
  const [image, setImage] = useState(user.imageURL);

  const buttonText = hasProfile ? "Update" : "Create";

  const onFileChange = (event) => {
    // Update the state
    setImage(event.target.files[0]);
  };

  const fromFileToString = (image) => {
    if (typeof image == "object") {
      return URL.createObjectURL(image);
    } else {
      return user.imageURL;
    }
  };
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={8}>
        <Paper className={classes.paper}>
          <Formik
            initialValues={{
              imageURL: image,
              displayName: user?.displayName,
              userId: user?.userId,
              skills: user?.skills,
              interests: user?.interests,
            }}
            onSubmit={(values, { setSubmitting }) => {
              try {
                values.imageURL = image;
                hasProfile
                  ? dispatch(updateCurrentUserProfile(values))
                  : dispatch(createCurrentUserProfile(values));
              } catch (ok) {}

              setSubmitting(false);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Avatar
                  variant="square"
                  className={classes.avatar}
                  alt={user?.imageURL}
                  src={fromFileToString(image)}
                />
                <Button
                  component="label"
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<PublishIcon />}
                  className={classes.button}
                >
                  Upload
                  <input type="file" hidden onChange={onFileChange} />
                </Button>
                <Field
                  component={TextField}
                  name="displayName"
                  type="text"
                  label="Profile name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  color="primary"
                  value={user?.displayName}
                ></Field>
                <Field
                  component={TextField}
                  name="skills"
                  type="text"
                  label="Skills"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  color="primary"
                  value={user?.skills}
                ></Field>
                <Field
                  component={TextField}
                  name="interests"
                  type="text"
                  label="Interests"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  color="primary"
                  value={user?.interests}
                ></Field>
                <Box pt={4}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {buttonText}
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
}
