
import React from 'react';
import Box from '@material-ui/core/Box';
import Colophon from '../Colophon/Colophon';

export default function Footer(props) {
  return (
    <Box pt={4} className={props.className}>
      <Colophon />
    </Box>
  );
}
