import React, { useMemo,useEffect } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Container} from "@material-ui/core";
import Footer from "../Footer/Footer";
import { getAllowedRoutes } from "../../routing/routes";
import PrivateRoute from "../../routing/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import {getAllUsers} from "../../store/actions/userAction";
import { getAllOrganizations } from "../../store/actions/organizationAction";
import {getCurrentUserProfile} from '../../store/actions/userAction';
import {Route, Redirect} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  footer: {
    marginTop: "auto",
  },
}));

const Main = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const users = useSelector((state) => state.users.users);
  const organizations = useSelector((state) => state.organizations.organizations);
  

  useEffect(() => {
    dispatch(getCurrentUserProfile()); 
     
    if(users.length === 0){
      dispatch(getAllUsers());
    }  
    if(organizations.length === 0){
      dispatch(getAllOrganizations());
    }  
  }, [dispatch, users.length,organizations.length]);

  const allowedRoutes = useMemo(() => getAllowedRoutes(), []);

  return (
    <main className={classes.main}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Switch>
          {allowedRoutes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
              roles={route.roles}
            />
          ))}
          <Route>
            <Redirect to="/organizations"/>
          </Route>
        </Switch>
        <Footer className={classes.footer} />
      </Container>
    </main>
  );
};

export default Main;
