import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HoursGraph from "./components/HoursGraph/HoursGraph";
import EmptyState from "./../components/EmptyState/EmptyState";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "28px",
  },
}));

const Hours = (props) => {
  const classes = useStyles();

  if (!props.organizationHourSummary?.byContract) {
    return (
      <EmptyState
        message={props.title + " - no information available"}
      ></EmptyState>
    );
  }

  return (
    <div className={classes.root}>
      <HoursGraph
        organizationHourSummary={props.organizationHourSummary}
        currentMonthTargetHours={props.currentMonthTargetHours}
        actions={props.actions}
        title={props.title}
      />
    </div>
  );
};

export default Hours;
