import React, { useState } from "react";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

import GroupIcon from "@material-ui/icons/Group";

import UsersPopoverList from "./UsersPopoverList";

export default function UsersPopover() {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
      <React.Fragment>
        <Tooltip title="Users" aria-label="Users">
          <IconButton color="inherit" onClick={handleClick}>
            <Badge badgeContent={0} color="secondary">
              <GroupIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <UsersPopoverList />
        </Popover>
      </React.Fragment>
    );
  }
  