import { Button } from "@material-ui/core";
import React, { useState } from "react";
import AlertDialog from "../AlertDialog/AlertDialog";

const ButtonWithAlert = ({
  buttonText,
  alertTitle,
  alertBody,
  confirmButtonText,
  confirmButtonDisabled = false,
  mainButtonDisabled = false,
  cancelButtonText,
  onConfirm,
  onCancel,
  buttonSize = "small", // small, medium, large
  alertButtonSize = "small",
  alertClassName,
  buttonClassName,
  buttonIcon,
  buttonVariant = "outlined",
  buttonColor = "primary",
  children,
}) => {
  const [alertVisible, setAlertVisible] = useState(false);

  return (
    <>
      <Button
        onClick={() => setAlertVisible(true)}
        size={buttonSize}
        className={buttonClassName}
        endIcon={buttonIcon}
        variant={buttonVariant}
        color={buttonColor}
        disabled={mainButtonDisabled}
      >
        {buttonText}
      </Button>
      <AlertDialog
        title={alertTitle}
        body={alertBody}
        open={alertVisible}
        setOpen={setAlertVisible}
        confirmButtonText={confirmButtonText}
        confirmButtonDisabled={confirmButtonDisabled}
        cancelButtonText={cancelButtonText}
        onConfirm={onConfirm}
        onCancel={onCancel}
        className={alertClassName}
        buttonSize={alertButtonSize}
        children={children}
      />
    </>
  );
};

export default ButtonWithAlert;
