import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";

import TeamProfilesList from "./components/TeamProfilesList/TeamProfilesList";
import { getAllCETeamMembers } from "../store/actions/ceTeamAction";
import Loader from "../components/Loader";

const TeamProfiles = () => {
  const dispatch = useDispatch();
  const ceTeamMembers = useSelector(
    (state) => state.ceTeamMembers.ceTeamMembers
  );

  const loading = useSelector((state) => state.ceTeamMembers.loading);

  useEffect(() => {
    if (ceTeamMembers.length === 0) {
      dispatch(getAllCETeamMembers());
    }
  }, [dispatch, ceTeamMembers.length]);

  const [input, setInput] = useState("");

  const editInput = (e) => {
    setInput(e.target.value);
  };

  const dynamicSearch = () => {
    if (ceTeamMembers !== null) {
      return ceTeamMembers
        .filter(
          (ceTeamMember) =>
            ceTeamMember.displayName
              ?.toLowerCase()
              .includes(input.toLowerCase()) ||
            ceTeamMember.interests
              ?.toLowerCase()
              .includes(input.toLowerCase()) ||
            ceTeamMember.skills?.toLowerCase().includes(input.toLowerCase())
        )
        .sort((firstMember, secondMember) =>
          firstMember.displayName > secondMember.displayName
            ? 1
            : secondMember.displayName > firstMember.displayName
            ? -1
            : 0
        );
    }
  };

  return (
    <Grid container spacing={3}>
      <Loader isLoading={loading} />
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <TeamProfilesList
          ceTeamMembers={dynamicSearch()}
          input={input}
          setInput={setInput}
          onChange={editInput}
        />
      </Grid>
    </Grid>
  );
};

export default TeamProfiles;
