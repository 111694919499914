import {
  callApiEndpoint,
  callApiEndpointWithoutCatch,
  REQUEST_TYPES,
} from "../../utils/storeFunctions";
import { usersReducerActionTypes } from "../actionTypes";
import { userEndpoints } from "../endpoints";

const {
  FETCH_USERS_START,
  FETCH_USER_START,
  FETCH_USER_PROFILE_START,
  SET_SELECTED_USER,
  CREATE_USER_START,
  UPDATE_USER_START,
  UPDATE_USER_PROFILE_START,
  CREATE_USER_PROFILE_START,
} = usersReducerActionTypes;

export const getAllUsers = () => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.GET_ALL,
      actionTypes: FETCH_USERS_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};

export const getCurrentUser = (userId) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.GET_PROFILE(userId),
      actionTypes: FETCH_USER_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};

export const getCurrentUserProfile = () => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.GET_CURRENT_USER_PROFILE,
      actionTypes: FETCH_USER_PROFILE_START,
      requestType: REQUEST_TYPES.GET,
      returnEmptyOn404: true
    });
  };
};

export const createCurrentUserProfile = (user) => {
  const interests = user.interests || " ";
  const skills = user.skills || " ";

  return (dispatch) => {
    const data = new FormData();
    data.append("UserId", user.userId);
    data.append("Skills", skills);
    data.append("DisplayName", user.displayName);
    data.append("Interests", interests);
    data.append("Image", user.imageURL);

    if (typeof user.imageURL == "object") {
      user.imageURL = URL.createObjectURL(user.imageURL);
    }

    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.CREATE_PROFILE,
      actionTypes: CREATE_USER_PROFILE_START,
      requestType: REQUEST_TYPES.POST,
      data: data,
      contentType: "application/json",
      message: "User profile successfully created!",
      returnPath: "/",
      payload: user,
    });
  };
};

export const updateCurrentUserProfile = (user) => {
  return (dispatch) => {
    const data = new FormData();
    data.append("UserId", user.userId);
    data.append("Skills", user.skills);
    data.append("Interests", user.interests);
    data.append("Image", user.imageURL);

    if (typeof user.imageURL == "object") {
      user.imageURL = URL.createObjectURL(user.imageURL);
    }

    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.UPDATE_PROFILE,
      actionTypes: UPDATE_USER_PROFILE_START,
      requestType: REQUEST_TYPES.POST,
      contentType: "multipart/form-data",
      data: data,
      payload: user,
      message: "User profile successfully updated!",
    });
  };
};

export const setCurrentUser = (userId) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_USER, payload: userId });
  };
};

export const createUser = (user) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.CREATE_USER,
      actionTypes: CREATE_USER_START,
      requestType: REQUEST_TYPES.POST,
      data: user,
      contentType: "application/json",
      returnPath: "/users",
      message: "User successfully created!",
      payload: user,
    });
  };
};

export const updateUser = (user) => {
  return (dispatch) => {
    const data = JSON.stringify({
      id: user.id,
      email: user.email,
      displayName: user.displayName,
      roles: user.roles,
      isSuspended: user.isSuspended,
    });

    callApiEndpoint({
      dispatch,
      endpoint: userEndpoints.UPDATE_USER,
      actionTypes: UPDATE_USER_START,
      requestType: REQUEST_TYPES.POST,
      data: data,
      contentType: "application/json",
      payload: user,
      returnPath: "/users",
      message: "User successfully updated!",
    });
  };
};
