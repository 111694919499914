const isFailed = (actionType) => {
  return actionType.substr(actionType.length - 6) === "FAILED" && true;
};

const errorHandlingMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (!action.suppressGlobalError && isFailed(action.type)) {
      next({
        type: "SET_ERROR",
        payload: true,
      });
    } else {
      next(action);
    }
  };

export default errorHandlingMiddleware;
