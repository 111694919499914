import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";

import authModule from "../../../../services/AuthModule";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    minWidth: "20rem",
    maxWidth: "30rem",
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
  },
  inline: {
    display: "inline",
  },
  icon: {
    color: theme.palette.primary.main,
    marginLeft: "10px"
  }
}));

export default function AccountPopoverList({ handleClose }) {
  const classes = useStyles();
  const accountInfo = authModule.getAccount();
  const picture = useSelector((state) => state.users.user.imageURL);
  const hasProfile = useSelector((state) => state.users.hasProfile);

  const dispatch = useDispatch();

  const logoutClicked = () => {
    authModule.logout();
    dispatch({ type: "RESET_STORE" });
  };

  const style = {
    background: "#D3D3D3"
  }

  return (
    <List className={classes.list}>
      <ListItem alignItems="flex-start" style={style} >
        <ListItemAvatar>
          <Avatar src={picture} alt="account avatar" />
        </ListItemAvatar>
        <ListItemText
          primary={accountInfo.name}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {accountInfo.idTokenClaims.signInName}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider component="li" />

      <ListItem
        button
        component={Link}
        to="/edit-profile"
        onClick={handleClose}
      >
        <ListItemIcon>
          <EditIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary={hasProfile ? "Edit profile" : "Create profile"}
        />
      </ListItem>

      <Divider component="li" />

      <ListItem button onClick={logoutClicked}>
        <ListItemIcon>
          <ExitToAppIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );
}
