import config from "../config";

export const announcementEndpoints = {
  GET: (notificationId) =>
    `${config.API_BASE_URL}/Notification/Get/${notificationId}?code=5gaevTjuyIiHCcJRciUI9X2CDlayNuXka3baK3EoAxPx56zVy2mrsw==`,
  PUBLISH:
    `${config.API_BASE_URL}/Notification/Insert?code=U6tAFB8jWDevw5CFWjDMFMYVhkThG2s9L2Dp6su3Z6aG3SRSGAWqDg==`,
};

export const organizationEndpoints = {
  GET_CURRENT_MONTH_TARGET_HOURS: (orgId) =>
    `${config.API_BASE_URL}/Reporting/GetCurrentPeriodTargetHoursOfOrganization/${orgId}?code=6Cm4dbZFJFvXsynxDlrd6GQhVrFlbUEbxmwUHwFrMkr9NcyksJtatw==`,
  GET_HOUR_SUMMARY_USER: (orgId) =>
    `${config.API_BASE_URL}/Reporting/User/GetHourSummaryOfOrganization/${orgId}?code=QrNXwWaxoLCTv6bmhjD9WnWdGShz5JND4kr2HC9FOICmk0XncD23Wg==`,
  GET_HOUR_SUMMARY_ADMIN: (orgId) =>
    `${config.API_BASE_URL}/Reporting/Admin/GetHourSummaryOfOrganization/${orgId}?code=7nF9bFavVlzvvpBTMu4MMKdH9SuRbSKTTP0cH6sCy3GSVEkJKh0Ylg==`,
  APPROVE_HOUR_SUMMARY: 
    `${config.API_BASE_URL}/Reporting/Admin/ApproveHourSummariesOfOrganizations?code=xxB9OXa42Y4x19NI5rNWNYEJkjJNJREYvw2t/Zhre8UXOteaEanSvw==`,
  SEND_CURRENT_MONTH_HOURS_REPORT:
    `${config.API_BASE_URL}/Reporting/SendCurrentHoursOfOrganization?code=6ucVWuXRE3KIJvKuUYBO1x2CQCk5yABhtAL6TBWHot9hvS/pBmp3DQ==`,
  SEND_NEW_MONTH_HOURS_REPORT:
    `${config.API_BASE_URL}/Reporting/SendNewMonthHoursOfOrganization?code=/26WiTZHNqNPhoDa/wMixlWZTLgJK9TPyAga1/8B9GDeNYxPuDE9qQ==`,
  GET_ALL_DATE_PERIODS:
    `${config.API_BASE_URL}/Reporting/GetAllDatePeriods?code=zQHtj5T2XYo8mSNvdkP8cS1BqaUBinNqUxL9/FTpG0ZzbmRBUB4VeA==`,
  REQUEST_TO_BORROW_HOURS:
    `${config.API_BASE_URL}/Organization/RequestToBorrowHours?code=kqYIJFKunTdaXDBkXTF6mKY4k4REqdIpNWagkdNPym2mwTbcosRUTQ==`,
  REQUEST_ADDITIONAL_HOURS:
    `${config.API_BASE_URL}/Organization/RequestAdditionalHours?code=qaQe6tzNgFKAma3g1vwu6CuuhLQ9bhY5zbCCyVUabqc43ivsohgsog==`,
  APPROVE_TO_BORROW_HOURS:
    `${config.API_BASE_URL}/Organization/ApproveToBorrowHours?code=NXdFw6nWRX9By6evkafzhnMNV6S81T2PT8cr/sohIkLsRFJPYvSA6A==`,
  APPROVE_ADDITIONAL_HOURS:
    `${config.API_BASE_URL}/Organization/ApproveAdditionalHours?code=Qok8kAcIQ1E7xxhZCCjj5ZAIqd2ZeaJnCUJGUcRattqIPGqE78Tf7g==`,
  GET_ORGANIZATION_USERS: (orgId) =>
    `${config.API_BASE_URL}/Organization/User/GetAllIds/${orgId}?code=HtVsi7OaCW2HTdTEFJfe3L9Pj1NkoiBaT4pzpC889LI32Qy8nvIwxw==`,
  GET_ALL: 
    `${config.API_BASE_URL}/Organization/GetAll?code=7tK3kdgRT4QNZPLVO8dTwWoQfuIGYTjdBQqf4yV8WZuUe96BqQUstQ==`,
  ASSIGN_USER:
    `${config.API_BASE_URL}/User/Organization/Update?code=UdffvqHWcPqP2Quu7Llp7tHDSywUJkjm5eC4VpbiNJtDCJEyac6FkA==`,
  GET_USER_ORGANIZATIONS:
    `${config.API_BASE_URL}/User/Organization/GetAllIds?code=3pZ69GCvZBeAjiWu5Texts0aw5QpoDw6bgksVtMI/IltdmzWMnemGg==`,
  GET_USER_ORGANIZATIONS_BY_USERID: (userId) =>
    `${config.API_BASE_URL}/User/Organization/GetAllIds/${userId}?code=3pZ69GCvZBeAjiWu5Texts0aw5QpoDw6bgksVtMI/IltdmzWMnemGg==`,
  GET_ORGANIZATION_MONTHLY_SUMMARY_HOURS: (orgId) =>
    `${config.API_BASE_URL}/Reporting/Admin/GetMonthlySummaryHours/${orgId}?code=hS3muHMYaJfesq7arldqIuMsChokk73KNTKkm9NBDhttOJcJxoGiLg==`,
};

export const notificationsEndpoints = {
  GET_NOTIFICATIONS_FEED: (minCount) =>
    `${config.API_BASE_URL}/Notification/GetFeed?minCount=${minCount}&code=O1w7ufFJlEWM2PvRMxjWk703bMpfnfGAJbf1Tgb/UNHxKGONUxsElQ==`,
  MARK_NOTIFICATIONS_SEEN:
    `${config.API_BASE_URL}/Notification/MarkAsSeen?code=1vjY7wNhr3pTe8EF3wypa1LaLyfuaZ18QUB/nY06bYMjAMWqeR8pVQ==`
};

export const userEndpoints = {
  GET_ALL:
    `${config.API_BASE_URL}/User/GetAll?code=RU6puNQIXVb8VsllC98QQdXLcmFfIIceA0xrdRFcSPQndhayOEluFg==`,
  GET_CE_TEAMMEMBERS:
    `${config.API_BASE_URL}/User/Profile/GetAllCe?code=DCO7PO/XuViJNyOgUL/3jKT1Kng5DyCwz9e0U0EsC4c4AsHAeVQwwA==`,
  GET_PROFILE: (userId) =>
    `${config.API_BASE_URL}/User/Profile/Get/${userId}?code=TYHhgf9ULnZd/gWWUZGTpAlv1SfiEYritjDtUTnD7CS5eWVlgFFlCQ==`,
  GET_CURRENT_USER_PROFILE:
    `${config.API_BASE_URL}/User/Profile/Get/?code=TYHhgf9ULnZd/gWWUZGTpAlv1SfiEYritjDtUTnD7CS5eWVlgFFlCQ==`,
  CREATE_PROFILE:
    `${config.API_BASE_URL}/User/Profile/Insert?code=rE3pDAXzSD/pglyycota16WKyXhik6WixatcRHmM7n4OnVlsh5BZrQ==`,
  UPDATE_PROFILE:
    `${config.API_BASE_URL}/User/Profile/Update?code=gZjY0KDMjeXjh71eA8KQRyClTUX77Lx0nmqotMaJEHQVhf4OdmqvHg==`,
  CREATE_USER:
    `${config.API_BASE_URL}/User/Insert?code=7PCKOVs7dcOxad5psFEcclHtYbb0hg1wCpCLyhkllIVftuGSX9xNXA==`,
  UPDATE_USER:
    `${config.API_BASE_URL}/User/Update?code=OecAEguBO1XRS/jZuSsajnqr76nZTioW/EaMJg1tFuFcNmrL2plTTQ==`,
};

export const roleEndpoints = {
  GET_ALL:
    `${config.API_BASE_URL}/User/Role/GetAll?code=BMMcRrXoQLsdTpfjawNthJHgeIgi84A1nTUHmrLiXam2k3H0t8IScQ==`,
};

export const eventEndpoints = {
  GET_FOR_MONTH: (month, year) =>
    `${config.API_BASE_URL}/Calendar/GetMonthDetails/${month}/${year}?code=I/Vtdx1f9RFaHXL9pvwPze/FZFnrlkE4ha8crYqjNha70/NuBAksfg==`,
};
