import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ChartConfiguration,
  LineController,
  BarController,
  Title
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "28px",
  },
}));


const HistoricalHours = (props) => {
  const classes = useStyles();

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
  );

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const data = {
    labels: props?.hours?.map(x => x.datePeriodName),
    datasets: [
      {
        type: 'line',
        label: 'contracted',
        backgroundColor: '#12239e',
        borderColor: '#12239e',
        borderWidth: 2,
        data: props?.hours?.map(x => x.reservedHours),
      },
      {
        type: 'line',
        label: 'target',
        backgroundColor: '#e66c37',
        borderColor: '#e66c37',
        data: props?.hours?.map(x => x.actualTargetHours),
        borderWidth: 2,
      },
      {
        type: 'bar',
        label: 'completed',
        backgroundColor: '#35a2eb',
        data: props?.hours?.map(x => x.hoursDone),
      },
    ],
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {props.title}
        </Typography>
        <Chart type='bar' data={data} options={options} />
      </CardContent>
    </Card>
  );
};

export default HistoricalHours;
