import { notificationsReducerActionTypes } from "../actionTypes";
import {
  REQUEST_TYPES,
  callApiEndpointNoMessages
} from "../../utils/storeFunctions";
import { notificationsEndpoints } from "../endpoints";

const {
  FETCH_ALL_NOTIFICATIONS_START,
  FETCH_ALL_NOTIFICATIONS_SUCCESS
} = notificationsReducerActionTypes;

export const getAllNotifications = (minCount = 5, showLoaders = false) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_NOTIFICATIONS_START });

    if (showLoaders)
      dispatch({ type: "SET_LOADER", payload: true });

    callApiEndpointNoMessages({
      endpoint: notificationsEndpoints.GET_NOTIFICATIONS_FEED(minCount),
      actionTypes: FETCH_ALL_NOTIFICATIONS_START,
      requestType: REQUEST_TYPES.GET
    }).then((response) => {
      dispatch({
        type: FETCH_ALL_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      })
    }).finally(() => {
      if (showLoaders)
        dispatch({ type: "SET_LOADER", payload: false });
    });
  }
}

export const markNotificationAsSeen = (notificationId, minCount = 5) => {
  return (dispatch) => {
    const data = JSON.stringify({
      "NotificationsIds": [notificationId],
    });

    callApiEndpointNoMessages({
      endpoint: notificationsEndpoints.MARK_NOTIFICATIONS_SEEN,
      contentType: "application/json",
      requestType: REQUEST_TYPES.POST,
      data: data
    })
      .then((response) => {
        callApiEndpointNoMessages({
          endpoint: notificationsEndpoints.GET_NOTIFICATIONS_FEED(minCount),
          actionTypes: FETCH_ALL_NOTIFICATIONS_START,
          requestType: REQUEST_TYPES.GET
        }).then((response) => {
          dispatch({
            type: FETCH_ALL_NOTIFICATIONS_SUCCESS,
            payload: response.data,
          })
        });
      });
  }
}
