import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& #alert-dialog-title": {
      paddingBottom: "10px",
    },
  },
}));

export default function AlertDialog({
  open,
  setOpen,
  onConfirm,
  onCancel,
  title,
  body,
  children,
  confirmButtonText = "Confirm",
  confirmButtonDisabled = false,
  cancelButtonText = "Cancel",
  buttonSize,
  className,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleClose();
    onConfirm && onConfirm();
  };

  const handleCancel = () => {
    handleClose();
    onCancel && onCancel();
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`${classes.dialogRoot}${className ? " " + className : ""}`}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {(body || children) && (
        <DialogContent>
          {body && (
            <DialogContentText id="alert-dialog-description">
              {body}
            </DialogContentText>
          )}
          {children && children}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel} variant="contained" size={buttonSize}>
          {cancelButtonText}
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          disabled={confirmButtonDisabled}
          color="primary"
          variant="contained"
          size={buttonSize}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
