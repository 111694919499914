import { uiReducerActionTypes } from '../actionTypes';

const { SNACKBAR_SUCCESS, SNACKBAR_ERROR, SNACKBAR_CLEAR } = uiReducerActionTypes;

const uiReducer = (state = {}, action) => {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
      };
    case SNACKBAR_ERROR:
      return {
        ...state,
        errorSnackbarOpen: true,
        errorSnackbarMessage: action.message,
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    default:
      return state;
  }
};

export default uiReducer;
