import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useParams } from "react-router-dom";
import { isEqual } from "lodash";
import {
  Paper,
  Grid,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Typography,
} from "@material-ui/core/";

import Title from "../../../components/Title/Title";
import {
  getOrganizationHourSummary,
  setCurrentOrganization,
  getOAOrganizationHourSummary,
  approveHoursSummaryOfOrganizations,
  getOrganizationMonthlySummaryHours,
  requestToBorrowHours,
  requestAddtionalHours,
  approveToBorrowHours,
  approveAdditionalHours,
  sendCurrentMonthHoursReport,
  sendNewMonthHoursReport,
  getAllOrganizationsByUserId,
  getOrganizationUsers,
} from "../../../store/actions/organizationAction";

import UsersPopover from "./UsersPopover";
import Hours from "../../../Hours/Hours.js";
import HistoricalHours from "../../../Hours/HistoricalHours.js";
import { useDispatch, useSelector } from "react-redux";
import authModule from "../../../services/AuthModule";
import { ROLE_NAMES } from "../../../utils/roles";
import ManageHoursForm from "../../../Hours/components/HoursGraph/ManageHoursForm";
import ButtonWithAlert from "../../../components/ButtonWithAlert/ButtonWithAlert";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import Loader from "../../../components/Loader";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    flexWrap: "wrap",
  },
  userButtonContainer: {},
  subtopButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    flexBasis: "100%",
  },
  button: {
    marginRight: theme.spacing(2),
  },
  additionalNotesInput: {
    marginTop: "8px",
    marginBottom: "24px",
  },
}));

const GoBack = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box pt={4}>
      <Button
        startIcon={<ArrowBackIcon />}
        className={classes.button}
        variant="outlined"
        onClick={() => history.goBack()}
      >
        {props.message ?? "Go Back"}
      </Button>
    </Box>
  );
};

export default function OrganizationDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const organization = useSelector(
    (state) => state.organizations.selectedOrganization
  );

  const currentUserOrganizations = useSelector(
    (state) => state.organizations.currentUserOrganizations
  );

  const organizationHourSummary = useSelector(
    (state) => state.organizations.organizationHourSummary
  );

  const organizationCurrentMonthTargetHours = useSelector(
    (state) => state.organizations.organizationCurrentMonthTargetHours
  );

  const organizationMonthlySummaryHours = useSelector(
    (state) => state.organizations.organizationMonthlySummaryHours
  );

  const organizations = useSelector(
    (state) => state.organizations.organizations
  );

  const loadings = useSelector((state) => state.organizations.loadings);

  const hasOneOrZeroOrganization =
    currentUserOrganizations?.organizationsIds?.length === 1;

  const isAdmin = authModule.isInRole(ROLE_NAMES.Admin);

  const isCEMember = authModule.isInRole(ROLE_NAMES.CETeamMember);

  var date = new Date();
  const currentMonthName = date.toLocaleString('en-US', { month: 'long' });
  const previousMonthName = new Date(date.setMonth(date.getMonth()-1)).toLocaleString('en-US', { month: 'long' });

  const isOnlyCeMember =
    authModule.getRoles().length === 1 &&
    authModule.isInRole(ROLE_NAMES.CETeamMember);

  const handleBorrowHoursRequest = (values) => {
    dispatch(requestToBorrowHours(organization.id, values.hours));
  };

  const handleAdditionalHoursRequest = (values) => {
    dispatch(requestAddtionalHours(organization.id, values.hours));
  };

  const handleBorrowHoursApproval = (values) => {
    dispatch(approveToBorrowHours(organization.id, values.hours));
  };

  const handleAdditionalHoursApproval = (values) => {
    dispatch(
      approveAdditionalHours(organization.id, values.hours, values.notes)
    );
  };

  const handleSendCurrentMonthHoursReport = () => {
    dispatch(sendCurrentMonthHoursReport(organization.id));
  };

  const handleSendNewMonthHoursReport = (values) => {
    dispatch(
      sendNewMonthHoursReport({
        organizationId: organization.id,
        additionalNotes: values.additionalNotes,
      })
    );
  };

  const handleFormSubmit = (nativeHandler, setSubmitting) => {
    nativeHandler();
    setSubmitting(false);
  };

  const params = useParams();
  const history = useHistory();
  const user = useSelector((state) => state.users.user);

  const findOrganization = organizations.find(
    (organization) => organization.id === +params.id
  );

  useEffect(() => {
    if (findOrganization) {
      const { id: orgId } = findOrganization;
      dispatch(setCurrentOrganization(findOrganization));
      dispatch(getOrganizationMonthlySummaryHours(orgId));
      if (isAdmin || isCEMember) {
        dispatch(getOrganizationUsers(orgId));
      }

      dispatch(getOrganizationHourSummary(orgId));
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [findOrganization?.id]);

  useEffect(() => {
    setLoading(
      loadings.organizationMonthlySummaryHours ||
        loadings.currentOrganizationUsers ||
        loadings.organizationOAHourSummary ||
        loadings.organizationHourSummary
    );
  }, [loadings]);

  if (!findOrganization) {
    // history.push("/");
    return (
      <Box justifyContent="center" display="flex">
        <Box>
          <Typography color="error" variant="h6">
            No Organization was found for ID: {params.id}
          </Typography>
          <Box justifyContent="center" display="flex">
            <GoBack />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Loader isLoading={loading} />
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={9}>
          <Paper className={classes.paper}>
            <Box pt={1} pb={2} className={classes.box}>
              <Title>{organization.name}</Title>

              {(isAdmin || isCEMember) && (
                <Box className={classes.userButtonContainer}>
                  <UsersPopover />
                </Box>
              )}
              {isAdmin && (
                <Box className={classes.subtopButtonsContainer}>
                  <ButtonWithAlert
                    buttonText="Send Current Month Hours"
                    alertTitle="Send A Current Month Hours Report?"
                    onConfirm={handleSendCurrentMonthHoursReport}
                    buttonClassName={classes.button}
                    buttonColor=""
                  />
                  <Formik
                    initialValues={{ additionalNotes: "" }}
                    onSubmit={handleSendNewMonthHoursReport}
                  >
                    {(props) => (
                      <Form>
                        <ButtonWithAlert
                          buttonText="Send New Month Hours"
                          alertTitle="Send A New Month Hours Report?"
                          confirmButtonDisabled={props.isSubmitting}
                          onConfirm={() =>
                            handleFormSubmit(props.handleSubmit, props.setSubmitting)
                          }
                          onCancel={() => props.setFieldValue("additionalNotes", "")
                          }
                          buttonClassName={classes.button}
                          buttonColor=""
                        >
                          <DialogActions>
                            <Button
                              size="small"
                              onClick={() => 
                                props.setFieldValue(
                                  "additionalNotes",
                                  ((props.values.additionalNotes) ? 
                                    props.values.additionalNotes + "\n" :
                                    "") + "* Unless we have a prior arrangement, I will assume any hours above the " + organizationHourSummary.previousMonth.appSupportTarget + " target will be deducted from " + currentMonthName + "."
                                )
                              }
                              variant="contained"
                            >
                              Deduced Hours
                            </Button>
                            <Button
                              size="small"
                              onClick={() =>
                                props.setFieldValue(
                                  "additionalNotes",
                                  ((props.values.additionalNotes) ?
                                    props.values.additionalNotes + "\n" :
                                    "") + "* Unused hours have become part of " + currentMonthName + "'s target, up to the bank limit on the Work Order."
                                )
                              }
                              variant="contained"
                            >
                              Added Hours
                            </Button>
                            <Button
                              size="small"
                              onClick={() =>
                                props.setFieldValue(
                                  "additionalNotes",
                                  ((props.values.additionalNotes) ?
                                    props.values.additionalNotes + "\n" :
                                    "") + "* ####### Additional Hours will appear on your " + previousMonthName + " invoice."
                                )
                              }
                              variant="contained"
                            >
                              Additional Hours
                            </Button>
                          </DialogActions>
                          <Field
                            type="text"
                            name="additionalNotes"
                            label="Additional Notes (optional)"
                            size="small"
                            variant="outlined"
                            component={TextField}
                            multiline
                            fullWidth
                            className={classes.additionalNotesInput}
                          />
                        </ButtonWithAlert>
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </Box>
            {isAdmin ? (
              <>
                <Hours
                  organizationHourSummary={
                    organizationHourSummary?.previousMonth
                  }
                  title="Last Month Hours Graph"
                />
                <Hours
                  organizationHourSummary={
                    organizationHourSummary?.currentMonth
                  }
                  currentMonthTargetHours={organizationCurrentMonthTargetHours}
                  title="Current Month Hours Graph"
                  actions={
                    <>
                      <ManageHoursForm
                        initialHours={
                          organizationCurrentMonthTargetHours.requestedHoursToBorrow
                        }
                        requestedHours={
                          organizationCurrentMonthTargetHours.requestedHoursToBorrow
                        }
                        approvedHours={
                          organizationCurrentMonthTargetHours.approvedHoursToBorrow
                        }
                        maxApprovableHours={
                          organizationCurrentMonthTargetHours.allowedToTransferByContract
                        }
                        modalTitle="Approve To Borrow Hours"
                        buttonText="Approve Borrow Hours"
                        handleSubmit={handleBorrowHoursApproval}
                      />
                      <ManageHoursForm
                        initialHours={
                          organizationCurrentMonthTargetHours.requestedAdditionalWork
                        }
                        requestedHours={
                          organizationCurrentMonthTargetHours.requestedAdditionalWork
                        }
                        approvedHours={
                          organizationCurrentMonthTargetHours.approvedAdditionalWork
                        }
                        modalTitle="Approve Additional Hours"
                        buttonText="Approve Additional Hours"
                        handleSubmit={handleAdditionalHoursApproval}
                        provideNotesInput={true}
                      />
                    </>
                  }
                />
              </>
            ) : (
              <>
                <Hours
                  title="Hours Graph"
                  organizationHourSummary={
                    organizationHourSummary?.currentMonth
                  }
                  currentMonthTargetHours={organizationCurrentMonthTargetHours}
                  actions={
                    <>
                      {!!organizationCurrentMonthTargetHours.allowedToTransferByContract && (
                        <ManageHoursForm
                          initialHours={
                            organizationCurrentMonthTargetHours.requestedHoursToBorrow
                          }
                          requestedHours={
                            organizationCurrentMonthTargetHours.requestedHoursToBorrow
                          }
                          maxApprovableHours={
                            organizationCurrentMonthTargetHours.allowedToTransferByContract
                          }
                          approvedHours={
                            organizationCurrentMonthTargetHours.approvedHoursToBorrow
                          }
                          modalTitle="Borrow Hours"
                          buttonText="Borrow Hours"
                          handleSubmit={handleBorrowHoursRequest}
                          requireExplicitConsent={isOnlyCeMember}
                          explicitConsentText="I am sure I want to borrow hours on behalf of the client"
                        />
                      )}
                      <ManageHoursForm
                        initialHours={
                          organizationCurrentMonthTargetHours.requestedAdditionalWork
                        }
                        requestedHours={
                          organizationCurrentMonthTargetHours.requestedAdditionalWork
                        }
                        approvedHours={
                          organizationCurrentMonthTargetHours.approvedAdditionalWork
                        }
                        modalTitle="Request Additional Hours"
                        buttonText="Request Additional Hours"
                        handleSubmit={handleAdditionalHoursRequest}
                        requireExplicitConsent={isOnlyCeMember}
                        explicitConsentText="I am sure I want to request additional hours on behalf of the client"
                        hoursMultipleOf={5}
                      />
                    </>
                  }
                />
              </>
            )}
            <HistoricalHours
              title="Historical Hours"
              hours={organizationMonthlySummaryHours}
            />
            {hasOneOrZeroOrganization ? null : (
              <GoBack message="Back to organizations page" />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
