import { organizationReducerActionTypes } from "../actionTypes";
import {
  callApiEndpoint,
  callApiEndpointNoMessages,
  REQUEST_TYPES,
} from "../../utils/storeFunctions";
import * as snackbarActions from "../actions/snackbarActions";
import { organizationEndpoints } from "../endpoints";

const {
  FETCH_ORGANIZATIONS_START,
  SET_SELECTED_ORGANIZATION,
  FETCH_ALL_DATE_PERIODS_START,
  FETCH_ORGANIZATION_HOUR_SUMMARY_START,
  FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
  FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED,
  FETCH_USER_ORGANIZATIONS_START,
  ASSIGN_USER_TO_ORGANIZATION_START,
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START,
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
  FETCH_OA_ORGANIZATION_HOUR_SUMMARY_FAILED,
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_START,
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_SUCCESS,
  APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_FAILED,
  REQUEST_TO_BORROW_HOURS_START,
  REQUEST_ADDITIONAL_HOURS_START,
  APPROVE_TO_BORROW_HOURS_START,
  APPROVE_TO_BORROW_HOURS_SUCCESS,
  APPROVE_TO_BORROW_HOURS_FAILED,
  APPROVE_ADDITIONAL_HOURS_START,
  APPROVE_ADDITIONAL_HOURS_SUCCESS,
  APPROVE_ADDITIONAL_HOURS_FAILED,
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_START,
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS,
  FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_FAILED,
  SEND_CURRENT_MONTH_HOURS_REPORT_START,
  SEND_NEW_MONTH_HOURS_REPORT_START,
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_START,
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_SUCCESS,
  FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_FAILED,
  FETCH_ORGANIZATION_USERS_START,
} = organizationReducerActionTypes;

export const getAllOrganizations = () => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.GET_ALL,
      actionTypes: FETCH_ORGANIZATIONS_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};

export const assignUserToOrganization = (userId, organizationIds) => {
  return (dispatch) => {
    const data = JSON.stringify({
      userId: userId,
      organizationsIds: organizationIds,
    });

    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.ASSIGN_USER,
      actionTypes: ASSIGN_USER_TO_ORGANIZATION_START,
      requestType: REQUEST_TYPES.POST,
      data: data,
      contentType: "application/json",
      payload: organizationIds,
      message: "User successfully updated!",
    });
  };
};

export const setCurrentOrganization = (orgId) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ORGANIZATION, payload: orgId });
  };
};

export const getAllOrganizationsByUserId = (userId) => {
  return (dispatch) => {
    let path = organizationEndpoints.GET_USER_ORGANIZATIONS;
    if (userId) {
      path = organizationEndpoints.GET_USER_ORGANIZATIONS_BY_USERID(userId);
    }
    callApiEndpoint({
      dispatch,
      endpoint: path,
      actionTypes: FETCH_USER_ORGANIZATIONS_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};

export const getGetAllDatePeriods = () => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.GET_ALL_DATE_PERIODS,
      actionTypes: FETCH_ALL_DATE_PERIODS_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};

export const getOrganizationHourSummary = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ORGANIZATION_HOUR_SUMMARY_START });
    dispatch({ type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_START });

    const functionResults = await Promise.allSettled([
      callApiEndpointNoMessages({
        endpoint: organizationEndpoints.GET_HOUR_SUMMARY_USER(orgId),
        requestType: REQUEST_TYPES.GET,
        timeout: 30000, // LOCAL API HOST ONLY
      }),
      callApiEndpointNoMessages({
        endpoint: organizationEndpoints.GET_CURRENT_MONTH_TARGET_HOURS(orgId),
        requestType: REQUEST_TYPES.GET,
        timeout: 30000, // LOCAL API HOST ONLY
      }),
    ]);

    if (functionResults[0].status === "fulfilled") {
      dispatch({
        type: FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
        payload: functionResults[0].value.data,
      });
    } else if (functionResults[0].reason.response?.status === 404) {
      dispatch({
        type: FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED,
        payload: functionResults[0].reason.message,
        requestData: functionResults[0].reason.request,
      });
    }

    if (functionResults[1].status === "fulfilled") {
      dispatch({
        type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS,
        payload: functionResults[1].value.data,
      });
    } else if (functionResults[1].reason.response?.status === 404) {
      dispatch({
        type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_FAILED,
        payload: functionResults[1].reason.message,
        requestData: functionResults[1].reason.request,
      });
    }
  };
};

export const getOAOrganizationHourSummary = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ORGANIZATION_HOUR_SUMMARY_START });
    dispatch({ type: FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START });
    dispatch({ type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_START });

    const functionResults = await Promise.allSettled([
      callApiEndpointNoMessages({
        endpoint: organizationEndpoints.GET_HOUR_SUMMARY_USER(orgId),
        requestType: REQUEST_TYPES.GET,
      }),
      callApiEndpointNoMessages({
        endpoint: organizationEndpoints.GET_HOUR_SUMMARY_ADMIN(orgId),
        requestType: REQUEST_TYPES.GET,
      }),
      callApiEndpointNoMessages({
        endpoint: organizationEndpoints.GET_CURRENT_MONTH_TARGET_HOURS(orgId),
        requestType: REQUEST_TYPES.GET,
        timeout: 30000, // LOCAL API HOST ONLY
      }),
    ]);

    if (functionResults[0].status === "fulfilled") {
      dispatch({
        type: FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
        payload: functionResults[0].value.data,
      });
    } else if (functionResults[0].reason.response?.status === 404) {
      dispatch({
        type: FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED,
        payload: functionResults[0].reason.message,
        requestData: functionResults[0].reason.request,
      });
    }

    if (functionResults[1].status === "fulfilled") {
      dispatch({
        type: FETCH_OA_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
        payload: functionResults[1].value.data,
      });
    } else {
      dispatch({
        type: FETCH_OA_ORGANIZATION_HOUR_SUMMARY_FAILED,
        payload: functionResults[1].reason.message,
        requestData: functionResults[1].reason.request,
      });
    }

    if (functionResults[2].status === "fulfilled") {
      dispatch({
        type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS,
        payload: functionResults[2].value.data,
      });
    } else if (functionResults[2].reason.response?.status === 404) {
      dispatch({
        type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: FETCH_ORGANIZATION_CURRENT_MONTH_TARGET_HOURS_FAILED,
        payload: functionResults[2].reason.message,
        requestData: functionResults[2].reason.request,
      });
    }
  };
};

export const getOrganizationMonthlySummaryHours = (orgId) => {
  return async (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.GET_ORGANIZATION_MONTHLY_SUMMARY_HOURS(orgId),
      actionTypes: FETCH_ORGANIZATION_MONTHLY_SUMMARY_HOURS_START,
      requestType: "get",
    });
  };
};

export const approveHoursSummaryOfOrganizations = (organizationIds) => {
  return (dispatch) => {
    const data = JSON.stringify({
      OrganizationsIds: organizationIds,
    });

    dispatch({ type: APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_START });

    callApiEndpointNoMessages({
      endpoint: organizationEndpoints.APPROVE_HOUR_SUMMARY,
      requestType: REQUEST_TYPES.POST,
      data: data,
      contentType: "application/json",
    })
      .then((response) => {
        dispatch({
          type: APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_SUCCESS,
          payload: response.data,
        });
        dispatch({ type: FETCH_ORGANIZATION_HOUR_SUMMARY_START });
        callApiEndpointNoMessages({
          endpoint: organizationEndpoints.GET_HOUR_SUMMARY_USER(organizationIds[0]),
          requestType: REQUEST_TYPES.GET,
        })
          .then((response) => {
            dispatch({
              type: FETCH_ORGANIZATION_HOUR_SUMMARY_SUCCESS,
              payload: response.data,
            });
            dispatch(snackbarActions.showSuccessSnackbar("CE Hours approved"));
          })
          .catch((error) => {
            dispatch({
              type: FETCH_ORGANIZATION_HOUR_SUMMARY_FAILED,
              payload: error.response,
              requestData: {},
            });
            dispatch(snackbarActions.showErrorSnackbar("An error has occured"));
          });
      })
      .catch((error) => {
        dispatch({
          type: APPROVE_HOUR_SUMMARY_OF_ORGANIZATION_FAILED,
          payload: error.response,
          requestData: {},
        });
        dispatch(
          snackbarActions.showErrorSnackbar(
            "An error has occured. Hours have not been approved"
          )
        );
      });
  };
};

export const requestToBorrowHours = (organizationId, hours) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.REQUEST_TO_BORROW_HOURS,
      actionTypes: REQUEST_TO_BORROW_HOURS_START,
      requestType: REQUEST_TYPES.POST,
      data: { organizationId, hours },
      payload: { hours },
      message: "Requested to borrow hours",
      useGlobalLoader: false,
    });
  };
};

export const requestAddtionalHours = (organizationId, hours) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.REQUEST_ADDITIONAL_HOURS,
      actionTypes: REQUEST_ADDITIONAL_HOURS_START,
      requestType: REQUEST_TYPES.POST,
      data: { organizationId, hours },
      payload: { hours },
      message: "Requested additional hours",
      useGlobalLoader: false,
    });
  };
};

export const approveToBorrowHours = (organizationId, hours) => {
  return (dispatch) => {
    dispatch({ type: APPROVE_TO_BORROW_HOURS_START });

    callApiEndpointNoMessages({
      endpoint: organizationEndpoints.APPROVE_TO_BORROW_HOURS,
      requestType: REQUEST_TYPES.POST,
      data: { organizationId, hours },
    })
      .then(async (response) => {
        dispatch({ type: APPROVE_TO_BORROW_HOURS_SUCCESS, payload: { hours } });

        // Update the hours summary that the Admin users see
        await callApiEndpoint({
          dispatch,
          endpoint:
            organizationEndpoints.GET_HOUR_SUMMARY_ADMIN(organizationId),
          actionTypes: FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START,
          requestType: REQUEST_TYPES.GET,
          useGlobalLoader: false,
        });

        dispatch(
          snackbarActions.showSuccessSnackbar("Approved to borrow hours")
        );
      })
      .catch((error) => {
        dispatch({
          type: APPROVE_TO_BORROW_HOURS_FAILED,
          payload: error.response,
          requestData: { organizationId, hours },
        });
        dispatch(snackbarActions.showSuccessSnackbar("An error has occured"));
      });
  };
};

export const approveAdditionalHours = (organizationId, hours, notes) => {
  return (dispatch) => {
    dispatch({ type: APPROVE_ADDITIONAL_HOURS_START });

    callApiEndpointNoMessages({
      endpoint: organizationEndpoints.APPROVE_ADDITIONAL_HOURS,
      requestType: REQUEST_TYPES.POST,
      data: { organizationId, hours, notes },
    })
      .then(async (response) => {
        dispatch({
          type: APPROVE_ADDITIONAL_HOURS_SUCCESS,
          payload: { hours, notes },
        });

        // Update the hours summary that the Admin users see
        await callApiEndpoint({
          dispatch,
          endpoint:
            organizationEndpoints.GET_HOUR_SUMMARY_ADMIN(organizationId),
          actionTypes: FETCH_OA_ORGANIZATION_HOUR_SUMMARY_START,
          requestType: REQUEST_TYPES.GET,
          useGlobalLoader: false,
        });

        dispatch(
          snackbarActions.showSuccessSnackbar("Approved additional hours")
        );
      })
      .catch((error) => {
        dispatch({
          type: APPROVE_ADDITIONAL_HOURS_FAILED,
          payload: error.response,
          requestData: { organizationId, hours, notes },
        });
        dispatch(snackbarActions.showSuccessSnackbar("An error has occured"));
      });
  };
};

export const sendCurrentMonthHoursReport = (organizationId) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.SEND_CURRENT_MONTH_HOURS_REPORT,
      actionTypes: SEND_CURRENT_MONTH_HOURS_REPORT_START,
      requestType: REQUEST_TYPES.POST,
      data: { organizationId },
      message: "Current month hours report sent",
      useGlobalLoader: false,
    });
  };
};

export const sendNewMonthHoursReport = ({
  organizationId,
  additionalNotes,
}) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.SEND_NEW_MONTH_HOURS_REPORT,
      actionTypes: SEND_NEW_MONTH_HOURS_REPORT_START,
      requestType: REQUEST_TYPES.POST,
      data: { organizationId, additionalNotes },
      message: "New month hours report sent",
      useGlobalLoader: false,
    });
  };
};

export const getOrganizationUsers = (orgId) => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: organizationEndpoints.GET_ORGANIZATION_USERS(orgId),
      actionTypes: FETCH_ORGANIZATION_USERS_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};
