import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from '@material-ui/core/styles';

const NavDrawerLink = ({ linkTo, icon, text, ...rest }) => {

  const useStyles = makeStyles((theme) => ({
    icon: {
      color: theme.palette.primary.main
    }
  }));

  const classes = useStyles();

  return (
    <ListItem button component={Link} to={linkTo} {...rest}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default NavDrawerLink;
