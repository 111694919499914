import React from 'react'
import { useHistory } from 'react-router-dom';

import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'

import { makeStyles } from '@material-ui/core/styles';

import './BigCalendar.scss'
import CalendarToolbar from './CalendarToolbar'

const locales = {
  'en-US': require( 'date-fns/locale/en-US' ),
}

const localizer = dateFnsLocalizer( {
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
} )

const useStyles = makeStyles( ( theme ) => ( {
  customCalendar: {
    "& .rbc-month-row": {
      overflow: 'visible'
    }
  }, 
  eventPto: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.warning.contrastText,
  },
  eventHoliday: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  eventDefault: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  eventSickLeave: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
} ) );

const components = {
  toolbar: CalendarToolbar
}

export default function BigCalendar( props ) {

  const history = useHistory();

  const classes = useStyles();

  const eventPropGetter = ( event ) => {
    let className = ''

    switch ( event.type ) {
      case 'PTO':
        className = classes.eventPto
        break;
      case 'PTO - Marriage':
        className = classes.eventPto
        break;
      case 'PTO - Bereavement':
        className = classes.eventPto
        break;
      case 'PTO - Paternity Leave':
        className = classes.eventPto
        break;
      case 'Holiday':
        className = classes.eventHoliday
        break;
      case 'Sick Leave':
        className = classes.eventSickLeave
        break;
      case 'Unspecified':
        className = classes.eventDefault
        break;
      default:
        className = classes.eventDefault
        break;
    };

    return {
      className: className
  }
}

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={props.events}
        views={[ 'month' ]}
        style={{ minHeight: 600 }}
        popup={true}
        showAllEvents={true}
        components={components}
        eventPropGetter={eventPropGetter}
        onNavigate={(date) => {
          props.setDate(date);
        }}
        onDoubleClickEvent={(event) => {
          history.push(`/team-profiles/${event.userId}`);
        }}
        className={classes.customCalendar}
      />
    </div>
  )
}
