import { generalReducerActionTypes } from '../actionTypes';

const { SET_LOADER, SET_ERROR } = generalReducerActionTypes;

const initialState = {
  loader: true,
  error: false,
};

const reducerMap = {
  [SET_LOADER]: (state = initialState, action) => {
    return {
      ...state,
      loader: action.payload,
    };
  },
  [SET_ERROR]: (state = initialState, action) => {
    return {
      ...state,
      error: action.payload,
    };
  },
};

const generalReducer = (state = initialState, action) => {
  let fn = null;
  fn = action.type && reducerMap[action.type];
  return fn ? fn(state, action) : state;
};

export default generalReducer;