import { callApiEndpoint, REQUEST_TYPES } from "../../utils/storeFunctions";
import { roleReducerActionTypes } from "../actionTypes";
import { roleEndpoints } from "../endpoints";

const { FETCH_ROLES_START } = roleReducerActionTypes;
export const getAllRoles = () => {
  return (dispatch) => {
    callApiEndpoint({
      dispatch,
      endpoint: roleEndpoints.GET_ALL,
      actionTypes: FETCH_ROLES_START,
      requestType: REQUEST_TYPES.GET,
    });
  };
};
