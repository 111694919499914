import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import { RichTextEditor } from "../components/RichTextEditor/RichTextEditor";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useDispatch, useSelector } from "react-redux";
import { publishAnnouncement } from "../store/actions/announcementActions";
import { Formik, Form, Field, FastField } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as yup from "yup";
import { getAllOrganizations } from "../store/actions/organizationAction";
import MultipleSelect from "../components/MultipleSelect/MultipleSelect";
import { getAllRoles } from "../store/actions/roleAction";
import { uniqBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  titleField: {
    marginBottom: "16px",
  },
  editorField: {
    marginTop: "16px",
  },
  checkboxField: {
    display: "inline-block",
  },
  lastRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  resetButton: {
    marginRight: "16px",
  },
}));

const announcementValidationSchema = yup.object({
  title: yup.string().trim().required("Please provide a title"),
  body: yup
    .string()
    .trim()
    .matches(
      /(?:<[a-z]+>[^<>]+<\/[a-z]+)|(?:<img)/g, // Any non-empty tag or an image tag
      {
        message: "Please provide some non-empty content",
      }
    )
    .required("Please provide some content."),
  organizationsIds: yup
    .array()
    .min(1, "Please specify the target organizations"),
  rolesIds: yup.array().min(1, "Please specify the target roles"),
  isEmailed: yup.bool().required("Please specify if an email must be sent"),
});

const CreateAnnouncement = () => {
  const dispatch = useDispatch();

  const organizations = useSelector(
    (state) => state.organizations.organizations
  );

  const roles = useSelector((state) => state.roles.roles);

  useEffect(() => {
    if (organizations.length === 0) {
      dispatch(getAllOrganizations());
    }

    if (roles.length === 0) {
      dispatch(getAllRoles());
    }
  }, [organizations, roles.length, dispatch]);

  const organizationsOptions = useMemo(
    () => [{ id: 0, name: "All" }, ...uniqBy(organizations, "id")],
    [organizations]
  );

  const rolesOptions = useMemo(
    () => [{ id: 0, name: "All" }, ...roles],
    [roles]
  );

  const publish = useCallback(
    ({ title, body, organizations, roles, isEmailed }) => {
      // If the "All" option is chosen for the value of a multiple select field,
      // transofrm that value to null. This is specific to the Publish Announcement endpoint - if null is provided for the rolesIds or organizationsIds
      // the notification will be sent to ALL roles (and/or organizations, respectively)
      const transofrmMultipleSelection = (values) => {
        return values.some((value) => value.id === rolesOptions[0].id)
          ? null
          : values.map((value) => value.id);
      };
      const validateEmptyArray = (values) => {
        return Array.isArray(transofrmMultipleSelection(organizations)) && transofrmMultipleSelection(organizations).length > 0 ? transofrmMultipleSelection(organizations) : null;

      }
      dispatch(
        publishAnnouncement({
          title: title,
          body: body,
          organizationsIds: validateEmptyArray(organizations),
          rolesIds: validateEmptyArray(roles),
          isEmailed: isEmailed,
        })
      );
    },
    [dispatch, rolesOptions]
  );

  const editor = useRef(null);
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Formik
            initialValues={{
              title: "",
              body: "",
              organizations: [],
              roles: [],
              isEmailed: false,
            }}
            validationSchema={announcementValidationSchema}
            validateOnChange={false}
            validateOnMount={false}
            onSubmit={(values, actions) => {
              publish(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              setFieldValue,
              setFieldTouched,
              handleReset,
              isSubmitting,
              values,
            }) => (
              <Form>
                <Field
                  name="title"
                  type="text"
                  component={TextField}
                  fullWidth
                  label="Title *"
                  margin="normal"
                  variant="outlined"
                  className={classes.titleField}
                />
                <FastField
                  name="body"
                  variant="outlined"
                  label="Body"
                  className={classes.editorField}
                >
                  {({ field, meta }) => (
                    <RichTextEditor
                      editorRef={editor}
                      value={field.value}
                      isReadOnly={false}
                      error={meta.error}
                      touched={meta.touched}
                      placeholder="Content *"
                      disabled={isSubmitting}
                      showCharsCounter
                      showWordsCounter
                      showToolbar
                      showStatusBar
                      onBlur={(newBody) => {
                        setFieldValue("body", newBody);
                        setFieldTouched("body", true, true);
                      }}
                    />
                  )}
                </FastField>
                <Field
                  name="organizations"
                  margin="normal"
                  component={MultipleSelect}
                  multiple
                  options={organizationsOptions}
                  label="Organizations *"
                  allOptionName="All"
                  disabled={isSubmitting}
                ></Field>
                <Field
                  name="roles"
                  margin="normal"
                  component={MultipleSelect}
                  multiple
                  options={rolesOptions}
                  label="Roles *"
                  allOptionName="All"
                  disabled={isSubmitting}
                ></Field>
                <div className={classes.lastRow}>
                  <Field
                    type="checkbox"
                    component={CheckboxWithLabel}
                    name="isEmailed"
                    Label={{ label: "Send emails?" }}
                    color="primary"
                    className={classes.checkboxField}
                  />
                  <div>
                    <Button
                      type="reset"
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={handleReset}
                      className={classes.resetButton}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      endIcon={values.isEmailed ? <MailOutlineIcon /> : null}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreateAnnouncement;
