import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  TablePagination,
  Box,
  Button,
  TextField,
} from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { setCurrentOrganization } from "../../../store/actions/organizationAction";
import { getAllOrganizationsByUserId } from "../../../store/actions/organizationAction";
import authModule from "../../../services/AuthModule";
import { ROLE_NAMES } from "../../../utils/roles";
import NotFoundMessage from "../../../components/NotFoundMessage/NotFoundMessage";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../components/Loader";

const TableContent = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const isEmpty = props.organizations.length === 0;
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.organizations.loading);
  const user = useSelector((state) => state.users.user);
  console.log(loading);

  useEffect(() => {
    if (authModule.getRoles().some((role) => role === ROLE_NAMES.Client)) {
      dispatch(getAllOrganizationsByUserId(user.userId));
    }
  }, [dispatch, user.userId]);

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleClick = (organization) => {
    dispatch(setCurrentOrganization(organization));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.setPage(0);
  };
  if (!loading && isEmpty) {
    return (
      <Box p={2} pb={0}>
        <NotFoundMessage
          message="You are not assigned to any organization yet"
          icon={<SearchIcon />}
        />
      </Box>
    );
  }
  return (
    <Box>
      <Loader isLoading={loading} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Contract Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.organizations
            ?.slice(
              props.page * rowsPerPage,
              props.page * rowsPerPage + rowsPerPage
            )
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.contractType}</TableCell>
                <TableCell>{row.isActive ? "active" : "inactive"}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    component={RouterLink}
                    to={`/organization-details/${row.id}`}
                    startIcon={<EditIcon />}
                    onClick={() => handleClick(row)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              // colSpan={3}
              count={props.organizations?.length}
              page={props.page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
};

const OrganizationsList = (props) => {
  return (
    <Paper>
      <Box p={2} pb={0}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          value={props.input}
          onChange={props.onChange}
          onKeyUp={(event) => {
            if (event.key === "Escape") {
              props.setInput("");
            }
          }}
          fullWidth
        />
      </Box>
      <TableContent
        organizations={props.organizations}
        page={props.page}
        setPage={props.setPage}
      />
    </Paper>
  );
};

export default OrganizationsList;
