import { ceTeamReducerActionTypes } from "../actionTypes";

const {
  FETCH_CE_TEAM_MEMBERS_START,
  FETCH_CE_TEAM_MEMBERS_SUCCESS,
  FETCH_CE_TEAM_MEMBERS_FAILED,
  FETCH_CE_TEAM_USER_PROFILE_START,
  FETCH_CE_TEAM_USER_PROFILE_SUCCESS,
  FETCH_CE_TEAM_USER_PROFILE_FAILED,
  UPDATE_CE_TEAM_MEMBER_SUCCESS,
  UPDATE_CE_TEAM_MEMBER_START,
  UPDATE_CE_TEAM_MEMBER_FAILED,
} = ceTeamReducerActionTypes;

const initialState = {
  ceTeamMembers: [],
  userProfile: [],
  loading: null,
  userProfileLoading: null,
  error: null,
};

const reducerMap = {
  [FETCH_CE_TEAM_MEMBERS_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_CE_TEAM_MEMBERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      ceTeamMembers: action.payload,
      loading: false,
    };
  },
  [FETCH_CE_TEAM_MEMBERS_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  [FETCH_CE_TEAM_USER_PROFILE_START]: (state, action) => {
    return {
      ...state,
      loading: true,
      userProfileLoading: true,
    };
  },
  [FETCH_CE_TEAM_USER_PROFILE_SUCCESS]: (state, action) => {
    return {
      ...state,
      userProfile: action.payload,
      loading: false,
      userProfileLoading: false,
    };
  },
  [FETCH_CE_TEAM_USER_PROFILE_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: true,
      userProfileLoading: false,
    };
  },
  [UPDATE_CE_TEAM_MEMBER_START]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [UPDATE_CE_TEAM_MEMBER_SUCCESS]: (state, action) => {
    const index = state.ceTeamMembers.findIndex(
      (member) => member.userId === action.payload.userId
    );
    const ceTeamMembers = [...state.ceTeamMembers];
    ceTeamMembers[index] = { ...action.payload };

    return {
      ...state,
      ceTeamMembers: [...ceTeamMembers],
      loading: false,
    };
  },
  [UPDATE_CE_TEAM_MEMBER_FAILED]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  },
};

const ceTeamReducer = (state = initialState, action) => {
  let fn = null;
  fn = action.type && reducerMap[action.type];
  return fn ? fn(state, action) : state;
};

export default ceTeamReducer;
