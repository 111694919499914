import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NotFoundMessage from "../../../../components/NotFoundMessage/NotFoundMessage";

import { getCETeamUserProfileById } from "../../../../store/actions/ceTeamAction";
import FaceIcon from "@material-ui/icons/Face";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "90%",
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

const ViewProfilePage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = Number(props.match.params.id);
  const userProfile = useSelector((state) => state.ceTeamMembers.userProfile);
  const loading = useSelector(
    (state) => state.ceTeamMembers.userProfileLoading
  );

  useEffect(() => {
    dispatch(getCETeamUserProfileById(userId));
  }, [dispatch, userId]);

  if (!loading && userProfile?.userId !== userId) {
    return (
      <NotFoundMessage
        message="The profile page cannot be found!"
        icon={<FaceIcon />}
      />
    );
  }

  if (userId !== userProfile.userId) {
    return <Loader isLoading={loading} />;
  } else {
    return (
      <Grid container spacing={3} justify="center">
        <Grid item xs={6} md={4}>
          <Card sx={{ maxWidth: 120 }} className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={userProfile?.imageURL}
                title={userProfile?.displayName}
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {userProfile?.displayName}
              </Typography>
              {!!userProfile?.skills.trim() && (
                <div>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="span"
                  >
                    {"Skills: "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                  >
                    {userProfile?.skills}
                  </Typography>
                </div>
              )}
              {!!userProfile?.interests.trim() && (
                <div>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="span"
                  >
                    {"Interests: "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                  >
                    {userProfile?.interests}
                  </Typography>
                </div>
              )}
              <Button
                startIcon={<ArrowBackIcon />}
                className={classes.button}
                variant="contained"
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

export default ViewProfilePage;
